import { useState } from "react";
import { Link } from 'react-router-dom';

export const Navbar = () => {
  const [isActive, setIsActive] = useState(false);

  const toggleNav = () => {
    setIsActive(!isActive);
  };

  return <div>
  <header className='header'>
    <nav className={`nav ${isActive ? 'active' : ''}`}>
      <Link to="/">
        <img className='logo' src="/images/newMenuLogo.png" alt="asdf" />
      </Link>
      <button className='menu-hamburger' style={{paddingLeft: "0px", paddingRight: "0px", }} onClick={toggleNav}></button>
    </nav>
  </header>
 </div>
}