import { useState } from "react";
import { Link } from 'react-router-dom';
import { Column, Columns } from "../components/Columns";
import { Element } from "react-scroll";

export const Footer = () => {
  const [isActive, setIsActive] = useState(false);

  const toggleNav = () => {
    setIsActive(!isActive);
  };

  return <Element>
    <Columns isSize={12} styles={{marginBottom: 40}}>
      <Column personalClass={"center"} >
        <div className='footer-container'>
          <p className='hepta-bold font-size-p'>Siga as nossas redes:</p>
          <Link to="/">
            <img className='img-footer' src="/images/footerLogo.png" alt="asdf" />
          </Link>
          <div className='box-footer'>
            <a  
              className='icon-footer'
              target="_blaak" 
              href="https://api.whatsapp.com/send?phone=5547988601310&text=Ol%C3%A1.%20Gostaria%20de%20saber%20mais%20sobre%20o%20formadores%20de%20mercado,%20aguardo%20informa%C3%A7%C3%B5es.">
              <img src="/images/whatsappIcon.png" alt="asdf" className='icon-footer' />
            </a>
            <a  
              className='icon-footer'
              target="_blaak" 
              href="https://www.instagram.com/formadoresdemercado/">
              <img src="/images/instagramIcon.png" alt="asdf" />
            </a>
            <a 
              className='icon-footer'
              target='_blaak'
              href="https://www.youtube.com/@formadoresdemercado">
              <img src="/images/youtubeIcon.png" alt="asdf"/>
            </a>
            <a 
              className='icon-footer' 
              target='_blaak' 
              href="https://www.tiktok.com/@formadoresdemercado">
              <img src="/images/tiktokIcon.png" alt="asdf"/>
            </a>
          </div>
          <p className="copyright-footer">
            <span>
              <Link className='text-yellow' to="/terms-and-conditions-course">Termos de uso Curso</Link>
              <br/><br/>
              <Link className='text-yellow' to="/terms-and-conditions-mentoring">Termos de uso Mentoria</Link>
            </span>
            <br/><br/>
            CNPJ: 50.652.770/0001-97
            <br/>
            Copyright © 2024. Todos os direitos reservados.
          </p>
        </div>
      </Column>
    </Columns>
  </Element>
}