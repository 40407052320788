import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import TermsAndConditionsCourse from './Pages/TermsAndConditionsCourse';
import TermsAndConditionsMentoring from './Pages/TermsAndConditionsMentoring';


const App = () => {
  return (
    <BrowserRouter>
      <Routes> {/* Switch is replaced by Routes in React Router v6 */}
        <Route path="/" element={<Home />} />
        <Route path="/terms-and-conditions-course" element={<TermsAndConditionsCourse />} />
        <Route path="/terms-and-conditions-mentoring" element={<TermsAndConditionsMentoring />} />
      </Routes>
    </BrowserRouter>
  );
}; 

export default App;
