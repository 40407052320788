import React, { useState } from 'react';

export const LiteYouTubeEmbed = ({ videoId, videoTitle }) => {
  const [activated, setActivated] = useState(false);
  const videoSrc = `https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0`;

  const activate = () => {
    setActivated(true);
  };

  const iframeMarkup = (
    <iframe
      src={videoSrc}
      title={videoTitle}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  );

  const liteMarkup = (
    <div onClick={activate} style={{ cursor: 'pointer', background: `url(https://i.ytimg.com/vi/${videoId}/hqdefault.jpg) center center / cover no-repeat`}}>
      <img className={`youtube-icon`} src="/images/youtube.png" alt="youtubeIcon"/>
    </div>
  );

  return <div style={{  width: '100%', height: '100%' }}>
      {activated ? iframeMarkup : liteMarkup}
    </div>;
};