import React from 'react';

const sizes = {
  1: 'is-1',
  2: 'is-2',
  3: 'is-3',
  4: 'is-4',
  5: 'is-5',
  6: 'is-6',
  7: 'is-7',
  8: 'is-8',
  9: 'is-9',
  10: 'is-10',
  11: 'is-11',
  12: 'is-12'
}

export const Columns = (props) => {
  const { children, styles, personalClass } = props;
  return <div className={`columns ${personalClass}`} style={styles}>
    {children}
  </div>
};

export const Column = (props) => {
  const {isSize, children, styles, personalClass} = props;
  const size = sizes[isSize];
  return <div className={`column ${personalClass} ${size}`} style={styles}>
    {children}
  </div>
};