import React, { useEffect } from 'react';
import { Navbar } from '../components/Navbar';
import { Column, Columns } from "../components/Columns";
import { Footer } from '../components/Footer';

const TermsAndConditionsMentoring = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  
  return (
  <div>
    <div className={`position-relative-mobile`}>
      {/* NAVBAR */}
      <Navbar />
    </div>
    <Columns personalClass={`is-centered terms-section`} styles={{marginBottom: 120, paddingTop: 160}}>
      <div className={`container is-max-desktop has-text-centered`}>
        <Column isSize={12} personalClass={`mobile-horizontal-padding-2`}>
          <h1 className={`title is-4 publica-sans-bold text-yellow`}>
            TERMOS DE USO DA PRESTAÇÃO DE SERVIÇO DE “MENTORIA CRYPTOPRO”
          </h1>
        </Column>
        <Column isSize={12} personalClass={`mobile-horizontal-padding-2`}>
          <p className={`text-justify`}>
            A.	Este Termo regula a assinatura para todos os usuários da “Mentoria CryptoPro”, para acesso através de um computador, celular, tablet ou outro dispositivos digitais através das plataformas utilizadas como telegrama, whatsapp e outros.
            <br/>
            B.	A empresa contratada é a FORMADORES DE MERCADO EDUCACAO LTDA, pessoa jurídica, brasileira, inscrita no CNPJ/ME sob o nº. 50.652.770/0001-97, sediada na Rua Jorge Czerniewicz, n.° 99, Box 713, Bloco B, Edifício Saint Sebastian, Bairro Czerniewicz, Cidade de Jaraguá do Sul, SC, CEP 89.255-000, responsável pela coleta do pagamento e todas as outras situações.
            <br/>
            C.	Estes Termos constituem um contrato vinculante entre você, como usuário(a), e a Formadores de Mercado Educação Ltda, e regem a assinatura da Mentoria Cryptopro, tratando-se de relação individualizada de aprendizado e desenvolvimento, na qual a FORMADORES DE MERCADO EDUCACAO LTDA fornece feedback, orientação, conselhos práticos e compartilha experiências para ajudar o(a) USUÁRIO(A) no seu desenvolvimento através do: (i) ensino prático sobre conceitos econômicos, ciclos de mercado de criptomoedas, bem como de (ii) auxilio no desenvolvimento da capacidade de análise de fatores que impactam os mercados, bem como na identificação de tendências e oportunidades de investimento, para tomada de decisão na área de criptomoedas 
            <br/>
            D.	AO ACEITAR ESTES TERMOS, VOCÊ DECLARA QUE CONHECE E CONCORDA com o seu conteúdo e com as demais políticas da Formadores de Mercado Educação Ltda aplicáveis. Tratando-se o presente termo de parte integrante da contratação da assinatura de Mentoria CryptoPro, comercializada através de site próprio, ou outra plataforma contratada, termo que se incorpora a compra realizada, ainda que sejam apresentadas em textos separados.
            <br/>
            E.	Após você aceitar este Termo e realizar as contratações devidas, a Formadores de Mercado Educação Ltda concede a você o acesso aos canais e ferramentas relacionadas a Mentoria CryptoPro, observadas as demais obrigações contratuais como a regularidade dos pagamentos e observâncias das regras e políticas aplicáveis.
            <br/> 
            F.	O respeito às condições destes Termos é essencial para o uso legítimo dos recursos disponibilizados. Se você usar dos serviços de modo incompatível com estes Termos, a Formadores de Mercado Educação Ltda pode aplicar diferentes medidas, a qualquer tempo, com ou sem aviso prévio. Essas medidas podem incluir, sem prejuízo de outras, (i) a suspensão da assessoria, (ii) o cancelamento do seu cadastro ou (iii) o encerramento da sua assessoria e acesso aos grupos nas plataformas envolvidas.
            <br/>
            G.	Ao aceitar este Termo, você autoriza que a Formadores de Mercado Educação Ltda reporte às autoridades competentes qualquer ato relacionado ao acesso ou uso das plataformas relacionadas que a Formadores de Mercado Educação Ltda considere ter indícios de irregularidades ou ilegalidades. O(a) Usuário(a) reportado não pode exigir que a Formadores de Mercado Educação Ltda pague qualquer tipo de indenização, por dano patrimonial ou extrapatrimonial, por consequência dessa comunicação.
            <br/>
            H.	Ao usar a assinatura ou ferramentas da Formadores de Mercado Educação Ltda com relação a qualquer Produto, você se responsabiliza por identificar, compreender e cumprir todas as leis, regras e regulamentações aplicáveis. Isso pode incluir, entre outras, normas de natureza cível, normas sobre propriedade intelectual e outras. 
            <br/>
            I.	A Formadores de Mercado Educação Ltda se reserva o direito de modificar estes Termos a qualquer tempo. Se estes Termos forem alterados, a Formadores de Mercado Educação Ltda publicará em suas páginas e plataformas de contato o termo revisado, e informará a data da última atualização ao final do documento (“versão atualizada em”). Também enviaremos a você uma notificação sobre as alterações antes da data de início da sua vigência. Se você discordar da nova versão dos Termos, você pode encerrar este Contrato com efeito imediato. Se você não encerrar seu Contrato antes da data de vigência da nova versão dos Termos, seu acesso contínuo ou seu uso dos serviços significará que você aceita as alterações.
            <br/>
            J.	Se a Formadores de Mercado Educação Ltda não exercer imediatamente algum direito que lhe seja cabível na forma da lei ou destes Termos, por qualquer motivo, ou mesmo se deixar de exercer algum direito em determinado caso, isso não significa que a Formadores de Mercado Educação Ltda estará renunciando ao exercício desses direitos. A Formadores de Mercado Educação Ltda pode exercer seus direitos a qualquer tempo, a seu critério, e, se a Formadores de Mercado Educação Ltda renunciar ao exercício de qualquer um de seus direitos individualmente, isso não significa que estará renunciando ao exercício de seus direitos como um todo.
            <br/>
            K. 	A CONCORDÂNCIA AO PRESENTE TERMO É ABSOLUTAMENTE INDISPENSÁVEL À ASSINATURA E UTILIZAÇÃO DOS SERVIÇOS PELO USUÁRIO. AO UTILIZAR OS SERVIÇOS E/OU ACESSAR AS PLATAFORMAS, O USUÁRIO DECLARA ESTAR DE ACORDO COM ESTE TERMO, RESPONSABILIZANDO-SE INTEGRALMENTE POR TODOS E QUAISQUER ATOS QUE PRATICAR DURANTE A VIGÊNCIA DA ASSINATURA, BEM COMO EM RELAÇÃO AOS DEMAIS SERVIÇOS E APLICAÇÕES A ELA RELACIONADOS.
            <br/><br/>

            OS SERVIÇOS CONTEMPLADOS PELA ASSINATURA DA ASSESSORIA “MENTORIA CRYPTOPRO”
            <br/>
            1.1. O escopo da assinatura de mentoria consiste em atendimentos online, contemplando chamadas de vídeo e troca de mensagens, de acordo com a necessidade e conveniência das partes para feedback, orientação, conselhos práticos e compartilhamento de experiências para ajudar o(a) usuário(a) no seu desenvolvimento.
            <br/>
            1.2. Os atendimentos serão realizados: a) via troca de mensagens (whatsapp) em número específico informado para o(a) usuário(a); e b) vídeo chamada, preferencialmente via Zoom, outros aplicativos similares de web conferência.
            <br/>
            1.2.1. O(A) usuário(a) ficará responsável pela verificação prévia do uso de aparelhos tecnológicos de suporte, como funcionamento adequado de notebooks, laptops, smartphone, áudios, microfones, internet, instalação correta dos aplicativos e outros que possam interferir no atendimento. É responsabilidade do usuário(a) dispor de internet estável, de alta velocidade, com zoom, ou outro aplicativo utilizado, instalado e testado, e em um ambiente reservado. 
            <br/>
            1.2.2. Os atendimentos da assinatura serão limitados, sob pena de desequilíbrio contratual, motivo pelo qual os atendimentos de vídeo chamada terão duração máxima de 1h (uma hora), em até no máximo 04 chamadas ao mês, não cumulativas.
            <br/>
            1.2.3. As tratativas via whatsapp se destinam para breves questionamentos e esclarecimentos, sendo que, múltiplas questões ou perguntas mais complexas, a critério da Formadores de Mercado Educação Ltda, deverão ser tratadas através de atendimento de vídeo chamada, com agendamentos com antecedência mínima de 48 (quarenta e oito horas), de acordo com a disponibilidade da Formadores de Mercado Educação Ltda.
            <br/>
            1.2.4. Caso o(a) usuário(a) não compareça pontualmente nos dias, horários combinados para atendimento, com tolerância máxima de 15 (quinze minutos), será considerada cumprida a hora de assessoria reservada, debitando dos saldo de horas mensais, considerando que a Formadores de Mercado Educação Ltda reservou o horário para o atendimento. 
            <br/>
            1.3. É terminantemente proibido solicitar modelos de investimento ou, de alguma forma, procurar transferir a responsabilidade pelas tomadas de decisão. Os atendimentos visão promover discussões, compartilhamento de insights, notícias, experiências e aprendizados práticos. Fica claro e expresso que o(a) usuário(a) assume completa responsabilidade na tomada de decisão, não havendo qualquer promessa ou garantia de lucros, considerando as particularidades do mercado financeiro e de criptoativos, sujeito a inúmeros acontecimentos geopolíticos, naturais e do comportamento humano em geral. 
            <br/>
            1.4. O usuário(a) se declara ciente de que não é permitida de sua parte a gravação, reprodução ou transmissão em tempo real dos atendimentos por qualquer meio.
            <br/>
            1.5. Canal de Comunicação no WhatsApp: enquanto estiver vigente a contratação, a Formadores de Mercado Educação Ltda concederá aos Usuários o direito de acesso, revogável e não exclusivo, a um canal de comunicação no aplicativo “WhatsApp”, através do qual o Assinante poderá interagir, sob sua exclusiva responsabilidade, com os demais assinantes da mentoria.
            <br/>
            1.6. Utilização do Canal de comunicação: ao utilizar a plataforma do serviço de envio de mensagens via WhatsApp, o Assinante declara de forma inequívoca o conhecimento e aceite das regras específicas do “WhatsApp”, bem como declara seu consentimento com o armazenamento pela Formadores de Mercado Educação Ltda de seu nome e número de telefone celular, que serão utilizados para identificação e como canal de comunicação, autorizando o tratamento desses dados para fins estatísticos.
            <br/>
            1.7. Regras do Canal de Comunicação: para a utilização do canal de comunicação os Usuários entendem e concordam com o cumprimento das seguintes regras:
            A. Seja responsável: não serão permitidas ofensas e mensagens desrespeitosas, nem conteúdos sensíveis ou que viole direitos e spam. Também não é permitido realizar ofertas de serviços ou produtos. Cada membro é responsável por suas mensagens;
            <br/>
            B. Pedido de definição de investimentos: é terminantemente proibido solicitar e/ou enviar modelos de investimento ou, de alguma forma, procurar transferir a responsabilidade pelas tomadas de decisão. O Canal foi criado para promover discussões, compartilhamento de insights, notícias, experiências e aprendizados. Portanto, pedimos que os membros busquem participar ativamente das conversas, compartilhando conhecimentos e experiências, assumindo suas responsabilidades nas tomadas de decisão;
            <br/>
            C. Suporte: toda e qualquer informação, reclamação ou suporte referente à Formadores de Mercado Educação Ltda devem ser feitas diretamente através dos canais oficiais de contato: e-mail (formadoresdemercadoeducacao@gmail.com) ou WhatsApp (47- 99136-2755). O Canal é um espaço exclusivo e oficial para interação de nossos Assinantes;
            <br/>
            D. Participação voluntária: o Canal é formado por Usuários e até professores da Formadores de Mercado Educação Ltda. A participação de todos é voluntária, por isso não mencione ou marque (@) ninguém, nem mesmo chame no particular sem autorização expressa;
            <br/>
            E. Banimento: o descumprimento dessas regras pode ocasionar punições variadas como suspensão ou exclusão do membro do nosso Canal. Qualquer mensagem está sujeita à revisão pela moderação;
            <br/>
            F. Alterações: a qualquer momento, conforme a necessidade, essas regras poderão ser alteradas para melhor desempenho e bem-estar dos participantes da Comunidade.
            <br/>
            1.6. O canal de comunicação pelo “WhatsApp” não poderá ser utilizado pelo Usuário para solicitação de suporte ou solução de dúvidas de qualquer natureza em relação à plataforma, sua assinatura de consultoria e demais questões administrativas.
            <br/>
            1.7. Processos de verificação conduzidos pela Formadores de Mercado Educação Ltda: A empresa leva a integridade da comunidade que utiliza suas plataformas muito a sério, e por isso se reserva o direito de realizar verificações antes ou depois do cadastro e contratação dos Usuários(as), Lembramos que os Usuários são os responsáveis por cumprir todas as regras aplicáveis aos produtos.
            <br/>
            1.8. Suporte aos Usuários: A Formadores de Mercado Educação Ltda prestará suporte ao Usuário no que diz respeito a eventuais problemas apresentados pela plataforma, assim como na solução de dúvidas e orientação quanto à utilização da plataforma e de suas funcionalidades. O suporte deverá ser solicitado pelo Usuário por meio dos canais oficiais de contato: e-mail (formadoresdemercadoeducacao@gmail.com) ou WhatsApp (47- 99136-2755), sendo certo que a equipe da Formadores de Mercado Educação Ltda responderá o contato do Usuário de forma mais breve possível.
            <br/><br/>

            DO PRAZO, VALOR E DAS HIPÓTESES DE DISSOLUÇÃO DOS SERVIÇO
            <br/>
            2.1. A presente assinatura é celebrada pelo valor inicial de R$ 990,00 (novecentos e noventa reais), paga de maneira antecipada, contemplando a assessoria pelo prazo de 06 (seis) meses, contados a partir da contratação.
            <br/>
            2.2. Decorrido o prazo de vigência inicial, a assinatura será automaticamente renovado por prazo indeterminado pelo valor mensal de R$ 97,00 (noventa sete reais), a menos que o(a) usuário(a) comunique expressamente o desejo pela não renovação, antes do término do prazo inicial. 
            <br/>
            2.2.1. Durante a vigência por prazo indeterminado, a assinatura poderá ser rescindida, sem aplicação de qualquer penalidade, mediante comunicação com 30 (trinta) dias de antecedência.
            <br/>
            2.2.2. O cancelamento da contratação não enseja qualquer dever da Formadores de Mercado Educação Ltda de realizar a devolução do pagamento da Assinatura já efetuado pelo Assinante, tendo em vista que o pagamento é feito de forma antecipada, de acordo com o item 2.1. acima. Isso significa que, mediante a solicitação de cancelamento, o Assinante continuará tendo acesso à Plataforma até o final do período contratado e, ao término deste, a cobrança do valor do Assinatura será interrompida pela Formadores de Mercado Educação Ltda.
            <br/>
            2.3. A respectiva assinatura poderá ser encerrada, ainda, de pleno direito, no caso de descumprimento de qualquer das cláusulas estipuladas neste instrumento.
            <br/>
            2.4. O USUÁRIO ASSINANTE DECLARA, DESDE JÁ, ESTAR DE ACORDO COM A RENOVAÇÃO AUTOMÁTICA DA CONTRATAÇÃO DA PLATAFORMA CASO O ASSINANTE NÃO SE MANIFESTE CONTRARIAMENTE À RENOVAÇÃO, NO PRAZO MENCIONADO NO ITEM 2.2 ACIMA. 
            <br/><br/>

            DAS REGRAS PARA PAGAMENTO 
            <br/>
            3.1. O acesso aos serviços e plataformas disponibilizadas ocorrerão mediante pagamento pelo usuário da (“Assinatura”). 
            <br/>
            3.1.1. O pagamento da Assinatura é feito de forma antecipada, concedendo ao(a) usuário(a) o direito de acesso aos serviços e plataformas pelo período contratado imediatamente subsequente ao pagamento. 
            <br/>
            3.1.2. Caso o dia em que o pagamento deva ser efetuado não se repita todos os meses (por exemplo: dia 31), o pagamento será cobrado no dia útil anterior ao que eventualmente ocorreria (por exemplo: dia 28 ou 30).
            <br/>
            3.1.3. O Assinante não ficará isento do pagamento da Assinatura mesmo que não venha a acessar as plataformas e/ou que não venha a solicitar ou usufruir dos atendimentos, sendo o pagamento devido pela simples contratação da assinatura e disponibilização dos serviços ao(a) usuário(a).
            <br/>
            3.2. Meios de Pagamento. Os pagamentos efetuados pelos usuários(as) deverão ser realizados dentro da própria Plataforma, ou em página específica e direcionada, por meio de um dos meios de pagamento disponibilizados pela Formadores de Mercado Educação Ltda. A compra via cartão de crédito acarretará pagamento automático da assinatura, mediante recorrência, a qual constará da fatura do respectivo cartão utilizado.
            <br/>
            3.3. Confirmação e Processamento dos Pagamentos. O processamento das informações de pagamento e a confirmação do pagamento serão realizados por sistemas de terceiros (ex. instituição financeira ou administradora do cartão de crédito) (“Empresas de Pagamento”), sendo as plataformas disponibilizadas uma mera interface entre o(a) usuário(a) e esses sistemas. 
            <br/>
            3.3.1. A Formadores de Mercado Educação Ltda não poderá intervir, nem será responsável, de qualquer maneira, pelos atos praticados pelas Empresas de Pagamento, assim como pelos resultados da relação dos(as) usuários(as) com as Empresas de Pagamento, uma vez que estas administram suas operações de forma independente. 
            <br/>
            3.4. Atraso nos Pagamentos. Em caso de atraso no pagamento dos valores referentes à Assinatura da Assessoria, o Assinante estará sujeito à cobrança de juros moratórios de 1% (um por cento) ao mês, bem como correção monetária pelo IGPM/FGV, até que ocorra a efetiva regularização dos valores em atraso.
            <br/>
            3.4.1. Ainda em caso de atrasos nos pagamentos, a Formadores de Mercado Educação Ltda poderá, a qualquer tempo, suspender o acesso do(a) usuário(a) às plataformas disponibilizadas e dos serviços, bem como promover a sua exclusão da plataforma e grupos disponibilizados, independentemente de aviso ou notificação prévia, e sem prejuízo da cobrança dos valores em aberto e adoção das medidas judiciais e extrajudiciais cabíveis.
            <br/>
            3.4.2. A liberação do acesso às plataformas e atendimentos só acontecerá mediante o pagamento de todos os valores em atraso.
            <br/>
            3.5. Preço Final e Reajuste. O valor da Assinatura pago pelo usuário(a) é final e não reembolsável, exceto na hipótese prevista no item 4.6 abaixo. A Formadores de Mercado Educação Ltda se reserva no direito de revisar e alterar o valor da Assinatura da Mentoria, mediante prévio aviso aos Assinantes, anteriormente à próxima data de cobrança recorrente.
            <br/>
            3.6. Hipóteses de Reembolso da Assinatura. A partir da data da contratação, o(a) usuários(a) terá o prazo máximo de 7 (sete) dias para solicitar o reembolso da Assinatura e ser ressarcido integralmente pelo valor pago.
            <br/>
            3.6.1. Na hipótese de cancelamento antecipado de Assinatura, após o período de 7 (sete) dias, não serão devolvidos quaisquer valores ao(a) usuários(a).
            <br/>
            3.7. Código Promocional. Caso a Formadores de Mercado Educação Ltda crie algum código promocional ou cupom de desconto, este deve ser usado de forma legal e adequada, pelo(a) respectivo(a) usuário(a), para a finalidade para o qual foi criado, seguindo todas as condições e termos aplicáveis. O código promocional poderá ser cancelado pela Formadores de Mercado Educação Ltda, independentemente de comunicação neste sentido, caso se verifique que foi transferido ou vendido a terceiros, ou foi utilizado com erro, fraude, ilegalidade ou violação às condições do respectivo código ou deste Termo.
            <br/><br/>

            CADASTRO, ACESSO, USO E EXCLUSÃO DA PLATAFORMA
            <br/>
            4.1. Acesso e Cadastro: a assinatura e os serviços serão disponibilizados somente para aqueles que tenham capacidade legal para contratar nos termos da Lei brasileira (“Usuários”). Não poderão utilizar a Plataforma os Usuários que não tenham esta capacidade, os menores de idade ou Usuários que já tenham sido previamente suspensos ou cancelados.
            <br/>
            4.2. Cadastro: para acessar os serviços e utilizar suas demais funcionalidades, é necessário que o(a) Usuário(a) efetue um cadastro através do site de venda, ou outra plataforma indicada, como a Hotmart ou outras. Para cadastrar-se, o(a) usuário(a) fornecerá determinados dados e informações, de maneira exata e verdadeira, fornecendo também, caso necessário e expressamente requerido pela Formadores de Mercado Educação Ltda, documentos que comprovem a sua identidade e a sua capacidade de contratar. Outros documentos e informações complementares poderão ser solicitados posteriormente pela Formadores de Mercado Educação Ltda. O Assinante assume o compromisso de manter as informações incluídas no cadastro atualizadas.
            <br/>
            4.3. A documentação fornecida pelo(a) usuário(a) no momento do cadastro deverá ser própria, atual, legal e vigente. A Formadores de Mercado Educação Ltda não assume qualquer responsabilidade de fiscalização ativa dos(as) usuários(as), de forma que prejuízos decorrentes de informações falsas ou imprecisas serão de exclusiva responsabilidade dos(as) usuários(as).
            <br/>
            4.4. A partir do cadastro, o(a) usuário(a) será titular de uma conta que somente poderá ser acessada de maneira pessoal. O(A) usuário(a) deverá informar o seu e-mail. Caso a Formadores de Mercado Educação Ltda detecte alguma conta criada a partir de informações falsas, por usuários que não possuam capacidade legal ou permissão para se cadastrar, ou que, de qualquer modo, violem este Termo, a respectiva conta será deletada.
            <br/>
            4.5. Acesso: O(A) usuário(a) se compromete a manter seu logins e contas seguros e fora do alcance de terceiros, e não permitirá que a sua conta ou acesso seja usada por outras pessoas. Dessa forma, o(a) usuário(a) se responsabiliza por todas as ações realizadas em seu acesso. A Formadores de Mercado Educação Ltda não se responsabiliza por operações efetuadas através do cadastro dos(as) usuários(as) nas plataformas disponibilizadas. Caso perceba o uso ou o acesso não autorizado, o(a) usuário(a) deverá comunicar tal fato à Formadores de Mercado Educação Ltda imediatamente para fins de suspensão do cadastro e resolução da questão.
            <br/>
            4.5.1. Manutenção: é de sua inteira responsabilidade manter o ambiente de seu dispositivo (computador, celular, tablet, whatsapp entre outros) seguro, com o uso de ferramentas disponíveis, como antivírus, firewall, duplo grau de confirmação de senha, entre outras, de modo a contribuir na prevenção de riscos eletrônicos.
            <br/>
            4.6. O compartilhamento dos dados de acesso e cadastro: não é permitido, sob hipótese alguma, que o(a) usuário(a) assinante tenha múltiplos acessos simultâneos, de modo que poderá ser imediatamente bloqueado se o sistema identificar acesso simultâneo.
            <br/>
            4.7. Da utilização: mediante a conclusão do cadastro/pagamento e adesão a este Termo, o(a) usuário(a) poderá usufruir dos serviços e plataformas disponibilizadas, conforme previsto neste Termo.
            <br/>
            4.7.1. É absolutamente proibida qualquer ação ou uso de dispositivo, software, ou outro meio com o propósito de gravar e/ou interferir nas atividades e operações. Qualquer intromissão, atividade ou tentativa de violação que seja contrária às leis, incluindo, sem limitação, as que versem sobre direito de propriedade intelectual, e/ou as proibições estipuladas neste Termo, serão passíveis da adoção das medidas legais pertinentes, inclusive de natureza criminal.
            <br/>
            4.8. Exclusão e Suspensão do Cadastro e do Acesso à Plataformas e serviços: A Formadores de Mercado Educação Ltda se reserva no direito de suspender e cancelar, a qualquer momento, o acesso de determinado(a) Usuário(a) às Plataformas em caso de suspeita de fraude, obtenção de benefício ou vantagem de forma ilícita, ou pelo não cumprimento de quaisquer condições previstas neste Termo ou na legislação aplicável. Nesses casos, não será devida qualquer indenização ao respectivo Usuário, e a Formadores de Mercado Educação Ltda poderá promover a competente ação de regresso, se necessário, bem como adotar quaisquer outras medidas necessárias para perseguir e resguardar os seus interesses.
            <br/>
            4.8.1. A Formadores de Mercado Educação Ltda poderá excluir da Plataforma os(as) Usuários(as) que não atuem em consonância com este Termo ou a legislação brasileira aplicável, independentemente de prévia notificação, oportunidade em que serão excluídos os cadastros e acessos do(a) Usuário(a) das Plataformas, sem prejuízo da cobrança de todos os valores devidos pelo Usuário em decorrência deste Termo e do uso das respectivas Plataformas e eventuais reparações.
            <br/><br/>

            ATUAÇÃO E RESPONSABILIDADES DA FORMADORES DE MERCADO EDUCAÇÃO LTDA
            <br/>
            5.1. O(A) Usuário(a) é exclusivamente responsável pelo uso das ferramentas e funcionalidades disponibilizadas, pela autenticidade e correção dos dados fornecidos para uso das plataformas, bem como por sua conduta ao longo do acompanhamento e utilização dos serviços disponibilizados, devendo respeitar a boa-fé, as regras deste Termo e a legislação aplicável. O uso das informações transmitidas através dos conteúdos disponibilizados é de responsabilidade exclusiva do(a) Usuário(a).
            <br/>
            5.2. É absolutamente proibida qualquer ação ou uso de dispositivo, software, ou outro meio com o propósito de interferir nas atividades e operações das Plataformas. Qualquer intromissão, atividade ou tentativa de violação, por parte do Usuário, que seja contrária às leis, incluindo, sem limitação, as que versem sobre direitos de propriedade intelectual, e/ou às proibições estipuladas neste Termo, serão passíveis das ações legais pertinentes.
            <br/>
            5.3. A Formadores de Mercado Educação Ltda não será, em hipótese alguma, responsabilizada por danos diretos ou indiretos, danos morais ou lucros cessantes que resultem: (i) de atrasos, por parte do(a) Usuário(a), na apresentação de documentos e informações necessárias ao seu cadastro e utilização das ferramentas disponibilizadas; (ii) de omissões  ou extravio de documentos e informações necessários ao cadastro do(a) usuário(a) para fins de utilização das Plataformas; (iii) fornecimento de informações inexatas, incompletas, falsas ou fraudulentas que ocasionem quaisquer prejuízos; (iv) da utilização dos conteúdos ministrados, tendo em vista que a maior parte deles decorre da experiência prática dos professores, podendo ou não se aplicar à realidade do(a) Usuário(a).
            <br/>
            5.4. Plataformas de Terceiros: É de conhecimento dos Usuários a existência de links para sites e aplicativos de terceiros, assim como a existência de tecnologias integradas com plataformas de terceiros. Isso não implica, de maneira alguma, que a Formadores de Mercado Educação Ltda endossa, verifica, garante ou possui qualquer ligação com os proprietários desses sites ou aplicativos, não sendo responsável pelo seu conteúdo, precisão, políticas, práticas ou opiniões. A Formadores de Mercado Educação Ltda recomenda que os Usuários leiam os termos de uso e políticas de privacidade de cada site de terceiros ou serviço que venha a visitar ou utilizar. A Formadores de Mercado Educação Ltda não será responsável por quaisquer danos decorrentes de questões relacionadas a serviços ou plataformas de terceiros.
            <br/><br/>

            DIREITOS DE PROPRIEDADE INTELECTUAL
            <br/>
            6.1. Propriedade Intelectual: Todos os direitos e funcionalidades disponibilizados através das plataformas são de propriedade intelectual exclusiva da Formadores de Mercado Educação Ltda, especialmente no que diz respeito aos atendimentos, vídeos, imagens, assim como no que diz respeito aos demais materiais, textos, imagens, layouts, software, códigos, bases de dados, gráficos, artigos, fotografias e demais conteúdos constantes nas plataformas e serviços disponibilizados. 
            <br/>
            6.2. O Conteúdo disponibilizado é protegido pela lei de direitos autorais e de propriedade intelectual. É proibido usar, copiar, reproduzir, modificar, traduzir, publicar, transmitir, distribuir, executar, fazer o upload, exibir, licenciar, vender ou explorar e fazer engenharia reversa do conteúdo, para qualquer finalidade. Qualquer uso não autorizado do conteúdo será considerado violação dos direitos autorais e de propriedade intelectual da Formadores de Mercado Educação Ltda.
            <br/>
            6.3. Não Aquisição de Direitos: O licenciamento de uso e acesso aos ambientes e plataformas, pela Formadores de Mercado Educação Ltda, não concede ao Usuário quaisquer direitos definitivos de propriedade intelectual sobre o conteúdo, bem como quaisquer outros ativos de propriedade intelectual de titularidade da Formadores de Mercado Educação Ltda na data de aceite do presente Termo, ou que venham a ser desenvolvidos pela Formadores de Mercado Educação Ltda no âmbito deste Termo, especialmente quanto ao material disponibilizado através das plataformas, marcas, materiais, nomes comerciais, nomes de domínio e demais sinais distintivos da Formadores de Mercado Educação Ltda, assim como os programas, banco de dados, redes e demais arquivos, sendo vedado ao Usuário efetuar pedido de registro, em território nacional ou no exterior, em seu nome ou em nome de terceiro, referente a qualquer propriedade industrial, software e/ou direito autoral que se relacione ou seja similar à ao conteúdo disponibilizado, ou a quaisquer outros ativos de propriedade intelectual de titularidade da Formadores de Mercado Educação Ltda.
            <br/>
            6.3.1. O Assinante desde já reconhece que eventuais invenções e melhorias que, eventualmente, venham a ser sugeridas por ele, no âmbito da execução do presente Termo, para o ambiente das plataformas, sejam elas descobertas ou projetadas, passíveis de registro como propriedade intelectual ou não, permanecerão de propriedade e de benefício exclusivo da Formadores de Mercado Educação Ltda.
            <br/>
            6.4. É proibido que o Usuário faça o download do conteúdo com o intuito de armazená-lo em banco de dados para oferecer a terceiros, gratuita ou onerosamente, ou constituir serviço que possa concorrer, de qualquer maneira, com os atendimentos e atividades realizadas no âmbito das plataformas, ou atentar contra os direitos e a proteção dos dados dos demais Usuários.
            <br/>
            6.5. Infringência de Direito de Propriedade Intelectual: qualquer intromissão, tentativa de intromissão, ou atividade que viole ou contrarie as leis de direito de propriedade intelectual e/ou as proibições estipuladas neste Termo, tornarão o Usuário passível das ações legais pertinentes por parte da Formadores de Mercado Educação Ltda, bem como das sanções aqui previstas, sendo ainda responsável pelas indenizações por eventuais sanções sofridas pela Formadores de Mercado Educação Ltda.
            <br/><br/>

            SEGURANÇA DA INFORMAÇÃO E PROTEÇÃO DE DADOS PESSOAIS
            <br/>
            7.1. Para ter acesso aos serviços e ambientes das plataformas, para usufruir de seu conteúdo e funcionalidades, os Usuários deverão fornecer determinados dados e informações, sendo que alguns deles podem se tratar de dados pessoais. Para mais informações sobre a privacidade dos dados pessoais dos Usuários, o Usuário deverá acessar a Política de Privacidade disponibilizada pela Formadores de Mercado Educação Ltda, bem como a Política de Privacidade disponibilizada pelas respectivas Plataformas de terceiros.
            <br/>
            7.2. Ao realizar qualquer atividade de tratamento de dados pessoais, a Formadores de Mercado Educação Ltda se compromete a:
            A. Aplicar as devidas medidas físicas, técnicas e organizacionais visando assegurar a integridade, a disponibilidade e a confidencialidade dos dados colocados sob sua guarda e responsabilidade, nos termos da legislação aplicável;
            <br/>
            B. Adotar medidas técnicas e administrativas de segurança da informação para evitar o uso indevido e não autorizado de dados pessoais;
            <br/>
            C. Adotar medidas eficazes e capazes de comprovar a observância e o cumprimento das normas de proteção de dados pessoais, bem como garantir a revisão periódica das medidas implementadas;
            <br/>
            D. Garantir a qualidade dos dados pessoais e a transparência sobre o tratamento em relação ao titular, bem como atender às suas requisições quando solicitado.
            <br/>
            7.3. Durante o tratamento de dados pessoais, a Formadores de Mercado Educação Ltda se responsabiliza pela manutenção de registro escrito das atividades de tratamento e pela adoção de padrões de segurança sustentados nas melhores tecnologias disponíveis no mercado, devendo:
            <br/>
            A. Restringir o acesso aos dados pessoais mediante a definição de pessoas habilitadas e responsáveis pelo tratamento;
            <br/>
            B. Adotar medidas técnicas e organizacionais de segurança que garantam a inviolabilidade, a confidencialidade, a disponibilidade e a integridade dos dados pessoais.
            <br/>
            7.4. Caso a Formadores de Mercado Educação Ltda seja destinatária de qualquer ordem judicial ou comunicação oficial que determine o fornecimento ou divulgação de dados pessoais tratados em razão deste Termo, deverá notificar o respectivo Usuário sobre o ocorrido.
            <br/>
            7.5. Na ocorrência de qualquer incidente de segurança que envolva os dados pessoais do Usuário, tratados em razão da presente relação contratual, a Formadores de Mercado Educação Ltda seguirá um plano estruturado por ele próprio, a fim de cientificar o Assinante e adotar as devidas medidas de mitigação.
            <br/>
            7.6. Ao término da relação contratual com o usuário assinante, por qualquer motivo, a Formadores de Mercado Educação Ltda, a seu exclusivo critério, ou para cumprimento de obrigação legal ou regulatória, ou em exercício regular de seus direitos, irá armazenar, eliminar, anonimizar, e/ou bloquear o acesso aos dados pessoais fornecidos pelo respectivo assinante, em caráter definitivo ou não.
            <br/><br/>

            CONFIDENCIALIDADE
            <br/>
            8.1. Todas as informações fornecidas pelo Usuário à Formadores de Mercado Educação Ltda, que estejam relacionadas, direta ou indiretamente, ao presente Termo, serão tratadas com o mais absoluto sigilo e a mais rigorosa confidencialidade, de modo a evitar, por qualquer meio ou forma, o seu conhecimento e/ou utilização por parte de terceiros, seja durante a vigência deste Termo, ou por 2 (dois) ano após o seu término (“Informações Confidenciais”).
            <br/>
            8.2. A Formadores de Mercado Educação Ltda poderá divulgar Informações Confidenciais somente para seus próprios colaboradores e/ou prepostos que tenham a efetiva e comprovada necessidade de conhecer tais informações, bem como deverá informá-los da existência de normas, políticas internas e/ou acordos aplicáveis, e que os Usuários estarão sujeitos às obrigações de confidencialidade previstas no presente Termo.
            <br/>
            8.3. As Partes se comprometem também a não manter sob arquivo ou guarda, por qualquer meio de registro, Informações Confidenciais, ou eventuais cópias, que possam ser erroneamente interpretadas ou mal utilizadas por terceiros.
            <br/><br/>

            COMO A FORMADORES DE MERCADO EDUCAÇÃO LTDA LIDA COM O CONTEÚDO QUE VOCÊ E OUTROS USUÁRIOS PRODUZEM?
            <br/>
            9.1. Criação de Conteúdo: A Formadores de Mercado Educação Ltda poderá, a seu exclusivo critério, permitir que você ou qualquer outro usuário forneça conteúdo ou informações de texto, imagem, áudio ou vídeo, pseudônimo, imagem e/ou voz, dados biométricos e outros dados pessoais, bem como demais características físicas. O fornecimento desses conteúdos também abrange a Comunidade oficial criada, com as regras inseridas na descrição do grupo no WhatsApp.
            <br/>
            9.2. Titularidade do Conteúdo Fornecido: Considerando que a Formadores de Mercado Educação Ltda promove um movimento de educação e promoção de conhecimento do universo das criptomoedas, qualquer Conteúdo de Usuário fornecido por você permanece de sua propriedade. Contudo, ao fornecê-lo para a Formadores de Mercado Educação Ltda através de qualquer das plataformas disponibilizadas, você outorga para a Formadores de Mercado Educação Ltda uma licença em nível mundial, por prazo de dois anos, em caráter total, universal, definitivo, irrevogável, irretratável, gratuita e transferível, e com direito de capturar, de armazenar, utilizar a sublicenciar, copiar, modificar, criar obras derivadas, distribuir, publicar, exibir esse Conteúdo de Usuário em todos os formatos e canais de distribuição possíveis, sem necessidade de novo aviso a você, e sem necessidade de qualquer pagamento, desde que isso esteja relacionado ao assunto de criptomoedas. Esse tópico também abrange a Comunidade oficial criada, com as regras inseridas na descrição do grupo no WhatsApp.
            <br/>
            9.2.1. Através da licença outorgada você reconhece e desde já concorda que a Formadores de Mercado Educação Ltda será a titular dos direitos patrimoniais sobre o conteúdo e as obras derivadas e poderá, a seu exclusivo critério, por si ou por meio de terceiros por esta autorizados, e independentemente de os Direitos Licenciados serem ou não utilizados, assim como independentemente de qualquer comunicação prévia, utilizar a Obra livremente, bem como seus extratos, trechos ou partes, podendo, exemplificativamente, adaptá-la para fins de produção de quaisquer obras audiovisuais novas, obras literárias, artísticas ou científicas, produtos comerciais, peças publicitárias ou promocionais.
            <br/>
            9.2.2. Nenhuma das possíveis e eventuais utilizações do conteúdo licenciado previstos neste Termo estarão sujeitas à limitação de tempo, de número de vezes ou de quantidade, podendo ocorrer no Brasil e/ou no exterior. O Licenciante não fará jus a qualquer remuneração, inclusive a título de royalties, direitos autorais ou que lhes são conexos, haja vista que a mesma abrange a cessão a título universal, irretratável e incondicional dos Direitos Licenciados pelo Licenciante sobre sua participação na Obra.
            <br/>
            9.3. Conteúdos Proibidos: É proibido qualquer Conteúdo de Usuário de caráter difamatório, calunioso, injurioso, violento, pornográfico, obsceno, ofensivo, ilícito ou que atente contra dignidade humana e costumes, conforme apuração da Formadores de Mercado Educação Ltda a seu critério exclusivo, inclusive informações de propriedade exclusiva pertencentes a outras pessoas ou empresas (ex. direito de autor, marcas), sem a expressa autorização do titular desses direitos, cuja violação não será de responsabilidade da Formadores de Mercado Educação Ltda. A proibição desses conteúdos também abrange a Comunidade oficial criada, com as regras inseridas na descrição do grupo no WhatsApp. 
            <br/>
            9.4. Exclusão do Conteúdo:  A Formadores de Mercado Educação Ltda poderá, mas não se obriga, a analisar, monitorar e remover Conteúdo de Usuário, a critério exclusivo da Formadores de Mercado Educação Ltda, a qualquer momento e por qualquer motivo, sem nenhum aviso a você. A exclusão desses conteúdos também abrange a Comunidade oficial criada, com as regras inseridas na descrição do grupo no WhatsApp.
            <br/><br/>

            VIOLAÇÃO DOS TERMOS DE USO
            <br/>
            10.1. Sem prejuízo de outras medidas, a Formadores de Mercado Educação Ltda poderá advertir, suspender ou cancelar, temporária ou definitivamente, a conta de um Usuário das Plataformas, a qualquer tempo e independentemente de prévio aviso ou notificação, e inclusive dentro do prazo legal de 7 (sete) dias previsto no artigo 49 da Lei nº 8.078/1990 - Código de Defesa do Consumidor, e iniciar as ações legais cabíveis, nas seguintes hipóteses:
            <br/>
            A. Se o Usuário: (i) violar qualquer dispositivo deste Termo; (ii) atrasar o pagamento dos valores devidos; (iii) descumprir com seus deveres de Usuário junto à Formadores de Mercado Educação Ltda e plataformas de Terceiros; ou, (iv) praticar atos fraudulentos ou dolosos;
            <br/>
            B. Se o Usuário efetuar, propositalmente, a prática de ‘Chargeback’, contratando as respectivas  plataformas e tendo acesso ao conteúdo oferecido, porém cancelando, de má-fé, a referida contratação dentro do prazo legal de cancelamento mencionado acima, apenas para obter o reembolso do valor pago, hipótese em que o referido reembolso não será devido;
            <br/>
            C. Se qualquer informação fornecida pelo Usuário estiver incorreta, desatualizada ou for inverídica; ou,
            <br/>
            D. Se a Formadores de Mercado Educação Ltda entender que qualquer atitude do Usuário tenha causado algum dano a terceiros ou a própria Formadores de Mercado Educação Ltda, inclusive a sua imagem e reputação perante o mercado, ou tenha a potencialidade de assim o fazer.
            <br/>
            10.2. O Usuário indenizará a Formadores de Mercado Educação Ltda por qualquer demanda promovida por outros Usuários ou quaisquer terceiros, decorrentes de suas atividades nas Plataformas disponibilizadas, por descumprimento deste Termo ou pela comprovada violação de qualquer lei ou direitos de terceiro.
            <br/><br/>

            DISPOSIÇÕES FINAIS
            <br/>
            11.1. Acordo: Este Termo constitui o acordo integral entre as Partes, relativamente ao acesso e uso das Plataformas envolvidas, e prevalece sobre quaisquer acordos anteriores.
            <br/>
            11.2. Alterações: Este Termo será revisto e atualizado periodicamente pela Formadores de Mercado Educação Ltda, que poderá alterá-lo, excluindo, modificando ou inserindo cláusulas ou condições, a seu exclusivo critério. No entanto, o Usuário será informado sempre que houver alguma alteração nas disposições deste Termo, para que possa ter ciência das novas condições.
            <br/>
            11.2.1. Caso o Assinante não concorde com as alterações no Termo, poderá rejeitá-las, mas, infelizmente, isso significará que o Usuário não poderá mais ter acesso e fazer parte da assinatura e de suas funcionalidades. Se, de qualquer maneira, o Usuário utilizar a Plataforma mesmo depois de comunicado sobre a alteração deste Termo, isso significará que o Usuário concordou com todas as modificações realizadas.
            <br/>
            11.2.2. Em caso de conflito entre as versões antigas deste Termo e as novas versões modificadas, as novas versões posteriores deverão prevalecer.
            <br/>
            11.3. Tributos: Os tributos de qualquer natureza relativos à celebração do presente Termo são de exclusiva responsabilidade do contribuinte devedor do respectivo tributo, conforme definido na legislação tributária.
            <br/>
            11.4. Relações Trabalhistas: Sob nenhuma hipótese ou em qualquer situação, se presumirá a eventual existência, ou se estabelecerá a presunção de qualquer vínculo empregatício, ou obrigações de caráter trabalhista e previdenciário entre as Partes, por si ou com os prepostos ou empregados da outra Parte, nem qualquer das Partes será fiadora das obrigações e encargos trabalhistas e sociais da outra Parte, a qual assume, neste ato, integral responsabilidade por tais obrigações, inclusive civil e penalmente.
            <br/>
            11.5. Anticorrupção: As Partes cumprirão e farão com que todos os seus empregados, subcontratados, consultores, agentes ou representantes cumpram a Lei 12.846/13, e demais leis de anticorrupção aplicáveis. Nenhuma das Partes pagará, oferecerá, prometerá ou dará, direta ou indiretamente, qualquer valor ou coisa de valor, incluindo quaisquer valores a ela pagos em decorrência dos serviços contratados, a qualquer funcionário ou oficial de um governo, empresa ou sociedade controlada pelo governo ou de propriedade do mesmo, partido político, candidato para cargo político, ou a qualquer outra pessoa estando ciente de, ou acreditando que tal valor ou item de valor será transmitido a alguém, para influenciar qualquer ação ou decisão por tal pessoa ou por qualquer órgão governamental com a finalidade de obter, reter ou conduzir negócios para quaisquer das Partes.
            <br/>
            11.6. Invalidade, Ilegalidade e Inaplicabilidade: Se qualquer disposição deste Termo for considerada inválida, ilegal ou inaplicável, isso não afetará as demais disposições, que permanecerão válidas, legais e aplicáveis.
            <br/>
            11.7. Dúvidas e Comunicações. Na medida do possível, todas as comunicações serão realizadas em meio eletrônico, por meio de e-mails para o endereço de e-mail fornecido pelo Usuário no ato de cadastro na Plataforma. Caso você tenha alguma dúvida, comentário ou sugestão, por favor, entre em contato conosco por meio do e-mail formadoresdemercadoeducacao@gmail.com. 
            <br/>
            11.8. Foro. As Partes elegem como meio competente para qualquer ação decorrente deste Contrato, o foro da cidade de Jaraguá do Sul/SC, com exclusão de qualquer outro, por mais privilegiado que seja.
            <br/><br/>

            O ASSINANTE, NESTE ATO, DECLARA E GARANTE À FORMADORES DE MERCADO EDUCAÇÃO LTDA QUE LEU, COMPREENDEU E CONCORDA COM TODOS AS DISPOSIÇÕES DESTE TERMO.
            <br/><br/>

            Termos de Uso da Prestação de Serviço de “Mentoria Cryptopro”
            <br/>
            CNPJ nº. 50.652.770/0001-97
            <br/>
            Endereço: Rua Jorge Czerniewicz, n.° 99, Box 713, Bloco B, Edifício Saint Sebastian, Bairro Czerniewicz, Cidade de Jaraguá do Sul, SC, CEP 89.255-000
            <br/><br/>

            Histórico de versões:
            <br/><br/>

            Versão 1.0
            <br/>
            08/11/2023
          </p>
        </Column>
      </div>
    </Columns>

    <hr style={{backgroundColor: "#3A2354"}} />

    {/* FOOTER */}
    <Footer />
  </div>);
};

export default TermsAndConditionsMentoring;