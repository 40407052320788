import React, { useState, useEffect } from 'react';
import { Column, Columns } from "../components/Columns";
import { Element } from "react-scroll";
import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';
import { Link } from 'react-router-dom';
import { LiteYouTubeEmbed } from "../components/LiteYouTubeEmbed";
import Slider from "react-slick";

const ModuleItem = ({ module, isActive, onModuleSelect }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const points = module.description.split('- ').filter(point => point);

  return (
    <div className={`module-item ${isActive ? 'active' : ''}`} onClick={() => onModuleSelect(module.id)}>
      <h2 className="module-title"><span className={`text-yellow`}>{module.modelTitle}</span> {module.title}</h2>
      {isActive && (
        <div className="module-description">
          {points.map((point, index) => (
            // Render each point as a separate paragraph with a bullet
            <p key={index}>- {point}</p>
          ))}
        </div>
      )}
    </div>
  );
};

const Home = () => {
  const [activeModuleId, setActiveModuleId] = useState(1);
  const [activeMentoringModuleId, setActiveMentoringModuleId] = useState(1);

  const modules = [
    { id: 1, modelTitle: 'Módulo 1 -', title: 'Economia Aplicada', description: '- Conceitos básicos de economia - Bancos Centrais - Juros e Mercados - Ciclos Econômicos '},
    { id: 2, modelTitle: 'Módulo 2 -', title: 'Análise Técnica', description: '- Candles - Análise de Tendências - Interpretação dos Padrões Gráficos' },
    { id: 3, modelTitle: 'Módulo 3 -', title: 'Análise Fundamentalista', description: '- Indicadores Econômicos - Análise Setorial de Ativos - Análise de Ativo Cripto ' },
    { id: 4, modelTitle: 'Módulo 4 -', title: 'Web3', description: '- Web 1.0 - Web 2.0 - Blockchain -Inteligência Artificial - Finanças Descentralizadas - Tokens não Fungíveis - NFT - Cold Wallet - Hot Wallet' },
    { id: 5, modelTitle: 'Módulo 5 -', title: 'Criptoativos', description: '- Conceito de Criptoativos - Bitcoin - Cripto como moeda de troca - Bitcoin – Reserva de valor - Tecnologia em Cripto - Altcoins	 - Ethereum' },
    { id: 6, modelTitle: 'Módulo 6 -', title: 'Finanças Descentralizadas', description: '- Exchanges - Exchanges Centralizadas - Exchanges Descentralizadas - Piscina de Liquidez - Estudo de Pares - Protocolo de Empréstimo Descentralizado - Stablecoins' },
    { id: 7, modelTitle: 'Módulo 7 -', title: 'Como comprar sua primeira Criptomoeda ', description: '- Como criar uma conta na corretora - Como criar uma carteira de criptomoedas (Metamask) - Como criar uma carteira de criptomoedas (Coinbase) - Como comprar sua primeira criptomoeda - Como sacar sua criptomoeda para carteira' },
  ];

  const mentoringModules = [
    { id: 1, modelTitle: 'Mentoria -', title: 'Carteira de Criptomoedas', description: 'Carteira de Criptomoedas Exclusiva' },
    { id: 2, modelTitle: 'Mentoria -', title: 'Piscina de Liquidez', description: 'Estratégias com Piscina de Liquidez minimizando a exposição ao Impermanent Loss' },
    { id: 3, modelTitle: 'Mentoria -', title: 'Airdrops', description: 'Estratégias para receber Airdrops' },
    { id: 4, modelTitle: 'Mentoria -', title: 'Comunidade', description: 'Grupo Exclusivo da Comunidade com Atualizações Diárias sobre o Mercado' },
    { id: 5, modelTitle: 'Mentoria -', title: 'Trades', description: 'Trades que são executados' },
    { id: 6, modelTitle: 'Mentoria -', title: 'Lives Semanais', description: 'Lives Semanais Exclusivas de Atualização sobre o Mercado toda segunda e quinta-feira' },
    { id: 7, modelTitle: 'Mentoria -', title: 'Suporte', description: 'Suporte para tirar dúvidas, mediante mensagens no WhatsApp e realização de video chamadas' },
  ];

  const onModuleSelect = (moduleId) => {
    setActiveModuleId(moduleId);
  };

  const onMentoringModuleSelect = (moduleId) => {
    setActiveMentoringModuleId(moduleId);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const settingsImages = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true,
    className: "center",
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          className: "center",
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          className: "center",
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    afterChange: (current) => setCurrentImage(`/images/feedback${current + 1}.jpeg`)
  };

  const settingsVideos = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          className: "center",
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          className: "center",
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const handleImageClick = (imageSrc) => {
    setCurrentImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
  <div>
    <Element name="start-page">
      <div className={`position-relative`}>
      {/* NAVBAR */}
      <Navbar />
      <Columns personalClass={`is-centered video-section gradient-background`}>
          <div className={`container is-max-desktop has-text-centered container-padding-desktop`}>
            <Column isSize={12} personalClass={`mobile-horizontal-padding-2`}>
                {/* <h1 className={`title is-2 soliden-trial-black text-yellow`}>
                  DESCUBRA COMO ENTRAR NO <br className={`display-block-desktop`} /> MUNDO DAS CRIPTOMOEDAS
                </h1> */}
                <h1 className={`title is-2 soliden-trial-black text-yellow`}>
                  De que outra maneira eu poderia te dizer que você está perdendo dinheiro ao não comprar Bitcoin?
                </h1>
                <h1 className={`title is-2 soliden-trial-black text-yellow mb-6`}>
                  Eu sei que você odeia perder dinheiro tanto quanto fofoca pela metade.
                </h1>
            </Column>
            {/* <Column isSize={12}>
              <div className={`video-card mobile-horizontal-padding-0`}>
                <LiteYouTubeEmbed videoId="v_DEDTaHqRE" videoTitle="YouTube video player" />
              </div>
            </Column> */}
            <Column isSize={12} personalClass={`mb-6`}>
              {/* <a onclick="return false;" href="https://pay.hotmart.com/D90012946F?checkoutMode=2" target='__blank'>
                <button className={`yellow-button`}>
                  Acesse agora e descubra como
                </button>
              </a> */}
              <a 
                target="_blaak" 
                href="https://api.whatsapp.com/send?phone=5547988601310&text=Ol%C3%A1.%20Gostaria%20de%20saber%20mais%20sobre%20o%20formadores%20de%20mercado,%20aguardo%20informa%C3%A7%C3%B5es.">
                <button type="button" className={`green-button`}>
                  <img src="/images/whatsappButtonIcon.png" alt="asdf"/> FALE CONOSCO
                </button>
              </a>
            </Column>
          </div>
        </Columns>
        <div className='box-links'>
          <a  
            className='icon-footer'
            target="_blaak" 
            href="https://www.instagram.com/formadoresdemercado/">
            <img src="/images/instagramIcon.png" alt="asdf" />
          </a>
          <a 
            className='icon-footer'
            target='_blaak'
            href="https://www.youtube.com/@formadoresdemercado">
            <img src="/images/youtubeIcon.png" alt="asdf"/>
          </a>
          <a 
            className='icon-footer' 
            target='_blaak' 
            href="https://www.tiktok.com/@formadoresdemercado">
            <img src="/images/tiktokIcon.png" alt="asdf"/>
          </a>
        </div>
      </div>
    </Element>

    {/* SEÇÃO 1 */}
    <Columns personalClass={`is-centered cards-section`}>
      <div className={`container is-max-desktop container-padding-desktop`}>
        <Column isSize={12} personalClass={`has-text-centered`}>
          <h2 className={`title is-2 publica-sans-bold has-text-white`}>
            A <span className={`text-yellow`}>Mentoria</span> dá acesso
          </h2>
        </Column>
        <Column isSize={12}>
          <Element id="section1">
            <Columns personalClass="is-centered course-section">
              <div className="modules-list">
                {mentoringModules.map(module => (
                  <ModuleItem
                    key={module.id}
                    module={module}
                    isActive={module.id === activeMentoringModuleId}
                    onModuleSelect={onMentoringModuleSelect}
                  />
                ))}
              </div>
            </Columns>
          </Element>
        </Column>
      </div>
    </Columns>

    {/* SEÇÃO 2 */}
    <Columns personalClass={`is-centered about-section`}>
      <div className={`container is-max-desktop has-text-centered`}>
        <Column isSize={12} personalClass={`mobile-horizontal-padding-2`}>
          <p className={`text-justify-mobile`}>
            Estamos aqui para ajudar você a dar os primeiros passos, desenvolver suas estratégias e crescer nesse mercado em expansão. Compartilharemos conhecimento, experiência e posições da carteira para que você entenda os fundamentos. Identifique oportunidades promissoras e tome decisões financeiras essenciais. Juntos, exploraremos práticas e abordagens para maximizar seu potencial de retorno e alcançar seus objetivos. 
            <br/>
            Embarque conosco nessa jornada de aprendizado e crescimento no mercado Web3.
          </p>
        </Column>
      </div>
    </Columns>

    {/* SEÇÃO 3 */}
    <Columns personalClass={`is-centered cards-section`}>
      <div className={`container is-max-desktop container-padding-desktop`}>
        <Column isSize={12} personalClass={`has-text-centered`}>
          <h2 className={`title is-2 publica-sans-bold has-text-white`}>
            Nossa trilha de conhecimento <br className={`display-block-desktop`} /> de 6 semanas rumo ao sucesso <span className={`text-yellow`}>CryptoPro</span>
          </h2>
        </Column>
        <Column isSize={12}>
          <Element id="section1">
            <Columns personalClass="is-centered course-section">
              <div className="modules-list">
                {modules.map(module => (
                  <ModuleItem
                    key={module.id}
                    module={module}
                    isActive={module.id === activeModuleId}
                    onModuleSelect={onModuleSelect}
                  />
                ))}
              </div>
            </Columns>
          </Element>
        </Column>
      </div>
    </Columns>

    {/* SEÇÃO 4 */}
    <Columns personalClass={`is-centered about-section`}>
      <div className={`container is-max-desktop has-text-centered`}>
        <Column isSize={12} personalClass={`mobile-horizontal-padding-2`}>
          <p className={`text-justify-mobile`}>
            Você já deve ter percebido a importância de ter conhecimento sólido sobre o mundo cripto. Não espere que outros tomem a dianteira. Agora é a hora de investir em você mesmo(a) e garantir que esteja no caminho certo para o sucesso financeiro. As vagas são limitadas e a demanda está aumentando rapidamente. Não perca a chance de se tornar um(a) CryptoPro.  Inscreva-se hoje mesmo e esteja à frente da curva, aproveitando todas as oportunidades que o mercado cripto oferece. O futuro das criptomoedas está chamando por você.
            <br/>
            Não deixe essa oportunidade escapar.  Junte-se ao CryptoPro e comece sua jornada
            para se tornar um(a) líder no mundo cripto.
          </p>
        </Column>
      </div>
    </Columns>

    {/* SEÇÃO 5 */}
    <Columns personalClass={`is-centered button-section1`}>
      <div className={`crypto-background`}>
        <img className={`crypto-left-image display-block-desktop`} src="/images/crypto-1.png" alt="cryptoImage"/>
        <img className={`crypto-right-image display-block-desktop`} src="/images/crypto-2.png" alt="cryptoImage"/>
        <img className={`crypto-left-image display-block-mobile`} src="/images/cryptoMobile-1.png" alt="cryptoImage"/>
        <img className={`crypto-right-image display-block-mobile`} src="/images/cryptoMobile-2.png" alt="cryptoImage"/>
      </div>
      <div className={`container is-max-desktop has-text-centered container-padding-desktop`}>
        <Column isSize={12} personalClass={`mobile-horizontal-padding-2`}>
            <h1 className={`title is-4 soliden-trial-black text-white`}>
              COMECE AGORA NO MUNDO <br className={`display-block-desktop`} /> DAS CRIPTOMOEDAS!
            </h1>
        </Column>
        <Column isSize={12}>
          <a onclick="return false;" href="https://pay.hotmart.com/Y86347503O?checkoutMode=2" target='__blank'>
            <button className={`yellow-button`}>
              QUERO ME INSCREVER NO CURSO
            </button>
          </a>
        </Column>
        <Column isSize={12}>
          <a onclick="return false;" href="https://pay.hotmart.com/D90012946F?checkoutMode=2" target='__blank'>
            <button className={`yellow-button`}>
              QUERO ME INSCREVER NA MENTORIA
            </button>
          </a>
        </Column>
      </div>
    </Columns>

    {/* SEÇÃO 6 */}
    <Columns personalClass={`feedback-section`}>
      <div className={`container is-max-desktop`}>
        <Column isSize={12}>
          <h1 className={`subtitle is-3 soliden-trial-black has-text-white`}>
            Veja o que dizem
            <br/>
            nossos alunos!
          </h1>
        </Column>
        <Column isSize={12} personalClass={`feedback-images-card`}>
          <div>
            <Slider {...settingsImages}>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(num => (
                <div key={num}>
                  <div className={`feedback-image`}>
                    <div 
                      style={{
                        backgroundImage: `url("/images/feedback${num}.jpg")`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                      }}
                      onClick={() => handleImageClick(`/images/feedback${num}.jpg`)}
                    />
                  </div>
                </div>
              ))}
            </Slider>

            {/* Modal */}
            {isModalOpen && (
              <div className="modal is-active">
                <div className="modal-background" onClick={closeModal}></div>
                <div className="modal-content">
                  <p className="image">
                    <img src={currentImage} alt="Current" />
                  </p>
                </div>
                <button className="modal-close is-large" aria-label="close" onClick={closeModal}></button>
              </div>
            )}
          </div>
        </Column>
        <Column isSize={12} personalClass={`feedback-videos-card`}>
          <div>
            <Slider {...settingsVideos}>
              <div key="1">
                <div className={`feedback-video`}>
                  <div>
                    <h4 className={`subtitle is-5 publica-sans-md text-yellow`}>
                      Carlos Eduardo Gretter
                    </h4>
                    <LiteYouTubeEmbed videoId="0kE6KVzwOBE" videoTitle="YouTube video player" />
                  </div>
                </div>
              </div>
              <div key="2">
                <div className={`feedback-video`}>
                  <div>
                    <h4 className={`subtitle is-5 publica-sans-md text-yellow`}>
                      Emerson Ceolin
                    </h4>
                    <LiteYouTubeEmbed videoId="zhe6W51frSU" videoTitle="YouTube video player" />
                  </div>
                </div>
              </div>
              <div key="3">
                <div className={`feedback-video`}>
                  <div>
                    <h4 className={`subtitle is-5 publica-sans-md text-yellow`}>
                      Bruno Ohlweiller
                    </h4>
                    <LiteYouTubeEmbed videoId="Uew7YZc6WJc" videoTitle="YouTube video player" />
                  </div>
                </div>
              </div>
              <div key="4">
                <div className={`feedback-video`}>
                  <div>
                    <h4 className={`subtitle is-5 publica-sans-md text-yellow`}>
                      Caue Jasper
                    </h4>
                    <LiteYouTubeEmbed videoId="JCJk69xAqrM" videoTitle="YouTube video player" />
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </Column>
      </div>
    </Columns>

    {/* SEÇÃO 7 */}
    <Columns personalClass={`is-centered button-section2`}>
      <div className={`crypto-background`}>
        <img className={`crypto-left-image display-block-desktop`} src="/images/crypto-3.png" alt="cryptoImage"/>
        <img className={`crypto-right-image display-block-desktop`} src="/images/crypto-4.png" alt="cryptoImage"/>
        <img className={`crypto-left-image display-block-mobile`} src="/images/cryptoMobile-3.png" alt="cryptoImage"/>
        <img className={`crypto-right-image display-block-mobile`} src="/images/cryptoMobile-4.png" alt="cryptoImage"/>
      </div>
      <div className={`container is-max-desktop has-text-centered container-padding-desktop`}>
        <Column isSize={12} personalClass={`mobile-horizontal-padding-2`}>
            <h1 className={`title is-4 soliden-trial-black text-white`}>
              Não deixe essa chance passar!
            </h1>
        </Column>
        <Column isSize={12}>
          <a onclick="return false;" href="https://pay.hotmart.com/Y86347503O?checkoutMode=2" target='__blank'>
            <button className={`yellow-button`}>
              ACESSE O CURSO AGORA MESMO
            </button>
          </a>
        </Column>
        <Column isSize={12}>
          <a onclick="return false;" href="https://pay.hotmart.com/D90012946F?checkoutMode=2" target='__blank'>
            <button className={`yellow-button`}>
              ACESSE A MENTORIA AGORA MESMO
            </button>
          </a>
        </Column>
      </div>
    </Columns>

    {/* SEÇÃO 8 */}
    <Columns personalClass={`who-section`}>
      <div className={`container is-max-desktop who-card`}>
        <Column personalClass={`is-half-desktop who-description`}>
          <h4 className={`subtitle is-3 soliden-trial-black has-text-white`}>
            Quem Somos
          </h4>
          <img className={`display-block-mobile`} src="/images/partners.png" alt="businessPartnersImage"/>
          <p>
            Somos um grupo de cinco amigos apaixonados por tecnologia e investimentos. 
            Com formações em TI, administração e engenharia, somos especialistas em criptomoedas, economia e investimentos em geral.
            Nosso objetivo é trazer o conhecimento cripto compartilhando insights e análises confiáveis para
            ajudá-los a compreender e aproveitar as oportunidades desse mercado.
            <br/>
            Buscamos de maneira qualificada e experiente, sempre atualizar a comunidade sobre as últimas tendências e desenvolvimentos. 
            Além de fornecer informações abrangentes sobre criptomoedas, também oferecemos orientações práticas para investimentos sólidos no mercado. 
            Estamos comprometidos em educar e capacitar nossos membros os denominados “Formadores” proporcionando uma abordagem acessível ao mundo das criptomoedas e dos investimentos.
            <br/>
            Explore nosso site para encontrar mais do que oferecemos como time! Se tiver dúvidas ou quiser entrar em contato, estamos aqui para ajudar. 
            Junte-se a nós nessa jornada de descoberta no emocionante universo das criptomoedas e dos investimentos.
          </p>
        </Column>
        <div className={`display-block-desktop who-image`}>
          <img src="/images/partners.png" alt="businessPartnersImage"/>
        </div>
      </div>
    </Columns>

    {/* SEÇÃO 9 */}
    <Columns personalClass={`is-centered contact-section`}>
      <div className={`container is-max-desktop has-text-centered`}>
        <Column isSize={12} personalClass={`has-text-centered contact-card`}>
          <h4 className={`subtitle is-4 soliden-trial-black has-text-white`}>
            Dúvidas? <br className={`display-block-mobile`}/> Fale com a gente!
          </h4>
          <p>
            Clique no botão abaixo para
            <br/>
            nos chamar no WhatsApp
          </p>
          <a 
            target="_blaak" 
            href="https://api.whatsapp.com/send?phone=5547988601310&text=Ol%C3%A1.%20Gostaria%20de%20saber%20mais%20sobre%20o%20formadores%20de%20mercado,%20aguardo%20informa%C3%A7%C3%B5es.">
            <button type="button" className={`green-button`}>
              <img src="/images/whatsappButtonIcon.png" alt="asdf"/> WHATSAPP
            </button>
          </a>
        </Column>
      </div>
    </Columns>

    <hr style={{backgroundColor: "#3A2354"}} />

    {/* FOOTER */}
    <Footer />
  </div>);
};

export default Home;