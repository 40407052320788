import React, { useEffect } from 'react';
import { Navbar } from '../components/Navbar';
import { Column, Columns } from "../components/Columns";
import { Footer } from '../components/Footer';

const TermsAndConditionsCourse = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  
  return (
  <div>
    <div className={`position-relative-mobile`}>
      {/* NAVBAR */}
      <Navbar />
    </div>
    <Columns personalClass={`is-centered terms-section`} styles={{marginBottom: 120, paddingTop: 160}}>
      <div className={`container is-max-desktop has-text-centered`}>
        <Column isSize={12} personalClass={`mobile-horizontal-padding-2`}>
          <h1 className={`title is-4 publica-sans-bold text-yellow`}>
            TERMOS DE USO DO CURSO “CRYPTOPRO”
          </h1>
        </Column>
        <Column isSize={12} personalClass={`mobile-horizontal-padding-2`}>
          <h4 className={`subtitle is-4 publica-sans-bold text-white`}>
            Políticas e esclarecimentos contratuais de uso.
          </h4>
        </Column>
        <Column isSize={12} personalClass={`mobile-horizontal-padding-2`}>
          <p className={`text-justify`}>
            A.	Este Termo regula o acesso ou uso para todos os usuários do curso CryptoPro, para acesso através de um computador, celular, tablet ou outro dispositivo digital através da plataforma hotmart, bem como as diversas plataformas utilizadas como telegrama e outros.
            <br/>
            B.	A empresa contratada é a FORMADORES DE MERCADO EDUCACAO LTDA, pessoa jurídica, brasileira, inscrita no CNPJ/ME sob o nº. 50.652.770/0001-97, sediada na Rua Jorge Czerniewicz, n.° 99, Box 713, Bloco B, Edifício Saint Sebastian, Bairro Czerniewicz, Cidade de Jaraguá do Sul, SC, CEP 89.255-000, responsável pela coleta do pagamento e todas as outras situações.
            <br/>
            C.	Estes Termos constituem um contrato vinculante entre você, como usuário(a), e a Formadores de Mercado Educação Ltda, e regem seu acesso e uso: (i) do curso no site hotmart.com e seus subdomínios; (ii) de quaisquer outros sites, interface, aplicativos ou outros meios nos quais a Formadores de Mercado Educação Ltda disponibilize seus recursos e ferramentas, inclusive aplicativos de celular, tablet ou de outros dispositivos eletrônicos e grupos oficiais; e (iii) de todos os serviços relacionados aos recursos disponibilizados pela Formadores de Mercado Educação Ltda.
            <br/>
            D.	AO ACEITAR ESTES TERMOS, VOCÊ DECLARA QUE CONHECE E CONCORDA com o seu conteúdo e com as demais políticas da Formadores de Mercado Educação Ltda aplicáveis. Tratando-se o presente termo de parte integrante da contratação do curso CryptoPro, comercializado através da plataforma Hotmart, ou outra plataforma contratada, termo que se incorpora a compra realizada, ainda que sejam apresentadas em textos separados. 
            <br/>
            E.	Após você aceitar este Termo e realizar as contratações devidas, a Formadores de Mercado Educação Ltda concede a você o acesso aos canais e ferramentas de uso relacionadas ao curso CryptoPro, observadas as demais obrigações contratuais como a regularidade dos pagamentos e observâncias das regras e políticas aplicáveis. 
            <br/>
            F.	O respeito às condições destes Termos é essencial para o uso legítimo dos recursos disponibilizados nas Plataformas. Se você usar a Plataforma de modo incompatível com estes Termos, a Formadores de Mercado Educação Ltda pode aplicar diferentes medidas, a qualquer tempo, com ou sem aviso prévio. Essas medidas podem incluir, sem prejuízo de outras, (i) a suspensão do acesso a um produto, (ii) o cancelamento do seu cadastro ou (iii) o encerramento da sua licença de acesso ao curso ou grupos nas respectivas Plataformas. 
            <br/>
            G.	Ao aceitar este Termo, você autoriza que a Formadores de Mercado Educação Ltda reporte às autoridades competentes qualquer ato relacionado ao acesso ou uso das plataformas relacionadas que a Formadores de Mercado Educação Ltda considere ter indícios de irregularidades ou ilegalidades. O(a) Usuário(a) reportado não pode exigir que a Formadores de Mercado Educação Ltda pague qualquer tipo de indenização, por dano patrimonial ou extrapatrimonial, por consequência dessa comunicação.
            <br/>
            H.	Ao usar os serviços, materiais ou ferramentas da Formadores de Mercado Educação Ltda com relação a qualquer Produto, você se responsabiliza por identificar, compreender e cumprir todas as leis, regras e regulamentações aplicáveis. Isso pode incluir, entre outras, normas de natureza cível, normas sobre propriedade intelectual e outras. 
            <br/>
            I.	A Formadores de Mercado Educação Ltda se reserva o direito de modificar estes Termos a qualquer tempo. Se estes Termos forem alterados, a Formadores de Mercado Educação Ltda publicará em suas páginas e plataformas de contato o termo revisado, e informará a data da última atualização ao final do documento (“versão atualizada em”). Também enviaremos a você uma notificação, dentro da Plataforma, sobre as alterações antes da data de início da sua vigência. Se você discordar da nova versão dos Termos, você pode encerrar este Contrato com efeito imediato. Se você não encerrar seu Contrato antes da data de vigência da nova versão dos Termos, seu acesso contínuo ou seu uso da Plataforma significará que você aceita as alterações.
            <br/>
            J.	Se a Formadores de Mercado Educação Ltda não exercer imediatamente algum direito que lhe seja cabível na forma da lei ou destes Termos, por qualquer motivo, ou mesmo se deixar de exercer algum direito em determinado caso, isso não significa que a Formadores de Mercado Educação Ltda estará renunciando ao exercício desses direitos. A Formadores de Mercado Educação Ltda pode exercer seus direitos a qualquer tempo, a seu critério, e, se a Formadores de Mercado Educação Ltda renunciar ao exercício de qualquer um de seus direitos individualmente, isso não significa que estará renunciando ao exercício de seus direitos como um todo.
            <br/>
            K. 	A CONCORDÂNCIA AO PRESENTE TERMO É ABSOLUTAMENTE INDISPENSÁVEL À UTILIZAÇÃO E ACESSO ÀS PLATAFORMAS PELO USUÁRIO. AO ACESSAR E UTILIZAR AS PLATAFORMAS, O USUÁRIO DECLARA ESTAR DE ACORDO COM ESTE TERMO, RESPONSABILIZANDO-SE INTEGRALMENTE POR TODOS E QUAISQUER ATOS QUE PRATICAR AO LONGO DOS ACESSOS À PLATAFORMA, BEM COMO EM RELAÇÃO AOS DEMAIS SERVIÇOS E APLICAÇÕES A ELA RELACIONADOS.
            <br/><br/>

            1. OS SERVIÇOS PRESTADOS PELA FORMADORES DE MERCADO EDUCACAO LTDA.
            <br/>
            1.1. Escopo dos Serviços da Formadores de Mercado Educação Ltda: ensino prático sobre conceitos econômicos, ciclos de mercado e criptomoedas, colaborando para o desenvolvimento da capacidade de análise de fatores que impactam os mercados, bem como a identificação de tendências e oportunidades de investimento, para tomada de decisão, mediante disponibilização de um conjunto de recursos online que possibilitam aos seus Usuários consumir conteúdos em diversos formatos digitais (vídeo, áudio, texto, grupos e etc.) voltados para a área de criptomoedas. 
            <br/>
            1.2. Inexistência de Promessas de Desempenho ou Resultados decorrentes do Uso das Plataformas disponibilizadas pela Formadores de Mercado Educação Ltda: a Formadores de Mercado Educação Ltda não promete nem garante que o(a) usuário(a) atingirá qualquer desempenho ou resultado com o uso das plataformas disponibilizadas ou aquisição de qualquer Produto. 
            <br/>
            1.3. Composição das aulas: tratam-se de Aulas online disponibilizadas através de plataformas, cuja duração e conteúdo serão definidos pela Formadores de Mercado Educação Ltda, a seu exclusivo critério. As Aulas serão previamente gravadas e ficarão disponíveis aos Usuários enquanto a contratação do respectivo serviço e plataforma estiverem vigentes.
            <br/>
            1.4. Do fornecimento de material complementar: em determinadas Aulas, os Usuários poderão ter acesso a um material de apoio, e, caso ocorra, não poderá, sob nenhuma circunstância, ser compartilhado com terceiros, nem revendido pelo Usuário.
            <br/>
            1.5. Canal de Comunicação no Telegram: enquanto estiver vigente a contratação, a Formadores de Mercado Educação Ltda concederá aos Usuários o direito de acesso, revogável e não exclusivo, a um canal de comunicação no aplicativo “Telegram”, através do qual o assinante poderá acompanhar informações e demais publicações que a Formadores de Mercado Educação Ltda entender interessante. 
            <br/>
            1.6. Suporte: toda e qualquer informação, reclamação ou suporte referente à Formadores de Mercado Educação Ltda devem ser feitas diretamente através dos canais oficiais de contato: e-mail formadoresdemercadoeducacao@gmail.com ou WhatsApp (47) 99136-2755. O Canal é um espaço exclusivo e oficial para interação de nossos Assinantes;
            <br/>
            1.7. Suporte aos Usuários: A Formadores de Mercado Educação Ltda prestará suporte ao Usuário no que diz respeito a eventuais problemas apresentados pela plataforma, assim como na solução de dúvidas e orientação quanto à utilização da plataforma e de suas funcionalidades. O suporte deverá ser solicitado pelo Usuário por meio dos canais/contatos informados na própria plataforma, sendo certo que a equipe da Formadores de Mercado Educação Ltda responderá o contato do Usuário de forma mais breve possível.
            <br/><br/>

            2. OS EVENTUAIS SERVIÇOS PRESTADOS POR TERCEIROS
            <br/>
            2.1. Prestação de Serviços de Terceiros: A Formadores de Mercado Educação Ltda pode disponibilizar links para sites ou recursos de terceiros. Por isso, eles estão sujeitos a termos e condições diferentes, inclusive com relação às práticas de privacidade. A Formadores de Mercado Educação Ltda não é responsável pela disponibilidade ou pela precisão dos Serviços de Terceiros, tampouco pelo seu conteúdo, pelos produtos ou pelos serviços disponíveis nesses Serviços de Terceiros. Os links para esses Serviços de Terceiros não representam endosso ou concordância da Formadores de Mercado Educação Ltda a seu respeito.
            <br/>
            2.2. Das demais plataformas: quando os Usuários realizam a aquisição do produto mediante alguma Plataforma, eles celebram um contrato sujeito a esses termos e aos termos da respectiva plataforma, devendo respeitar os respectivos termos relacionadas e as respectivas políticas de uso, privacidade e pagamento.
            <br/>
            2.3. Do conteúdo das plataformas: cabe à Formadores de Mercado Educação Ltda desenvolver e manter atualizado o ambiente das plataformas utilizadas, para que os Usuários possam ter acesso às Aulas e demais funcionalidades das plataformas. A Formadores de Mercado Educação Ltda poderá interromper a disponibilidade das Plataformas, a qualquer tempo, em paradas programadas, sem a necessidade de aviso prévio aos Usuários, esforçando-se, contudo, para que tais paradas sejam realizadas fora do horário comercial. A Formadores de Mercado Educação Ltda não é obrigada a manter o ambiente das plataformas disponível indefinidamente, não havendo qualquer tipo de responsabilidade perante os Usuários em caso de uma eventual indisponibilidade ou necessidade de manutenção não programada.
            <br/>
            2.4. Acesso à Internet e às Plataformas ou ferramentas: Devido à natureza da Internet, a Formadores de Mercado Educação Ltda não pode garantir que as Plataformas estejam disponíveis e acessível ininterruptamente. Além disso, a Formadores de Mercado Educação Ltda pode restringir a disponibilidade das plataformas ou ferramentas ou de certas áreas ou recursos a ela relacionados, se necessário, considerando os limites de capacidade, a segurança ou a integridade de seus servidores, bem como para realizar medidas de manutenção ou aprimoramento dos seus serviços. A Formadores de Mercado Educação Ltda não pode ser responsabilizada pelo Usuário ou por qualquer terceiro em função do impedimento ou alteração na forma de acesso à Plataforma e aos Serviços. A Formadores de Mercado Educação Ltda pode melhorar e alterar a Plataforma a qualquer tempo, seja para modificar, substituir ou remover Serviços existentes, ou para adicionar Serviços novos.
            <br/><br/>

            3. CADASTRO, ACESSO, USO E EXCLUSÃO DA PLATAFORMA
            <br/>
            3.1. Acesso e Cadastro: a Plataforma será disponibilizada somente para aqueles que tenham capacidade legal para contratar nos termos da Lei brasileira (“Usuários”). Não poderão utilizar a Plataforma os Usuários que não tenham esta capacidade, os menores de idade ou Usuários cujo acesso à Plataforma já tenha sido previamente suspenso ou cancelado.
            <br/>
            3.2. Cadastro: para acessar a Plataforma, ter acesso às Aulas e utilizar suas demais funcionalidades, é necessário que o Usuário efetue um cadastro na Plataforma indicada, como a Hotmart ou outras. Para cadastrar-se, o Usuário fornecerá determinados dados e informações, de maneira exata e verdadeira, fornecendo também, caso necessário e expressamente requerido pela Formadores de Mercado Educação Ltda, documentos que comprovem a sua identidade e a sua capacidade de contratar. Outros documentos e informações complementares poderão ser solicitados posteriormente pela Formadores de Mercado Educação Ltda. O Assinante assume o compromisso de manter as informações incluídas no cadastro atualizadas.
            <br/>
            3.3. A documentação fornecida pelo Usuário no momento do cadastro deverá ser própria, atual, legal e vigente. A Formadores de Mercado Educação Ltda não assume qualquer responsabilidade de fiscalização ativa dos Usuários, de forma que prejuízos decorrentes de informações falsas ou imprecisas serão de exclusiva responsabilidade dos Usuários.
            <br/>
            3.4. A partir do cadastro, o Usuário será titular de uma conta que somente poderá ser acessada mediante senha pessoal. O Usuário deverá informar o seu e-mail, sendo certo que uma senha provisória poderá ser fornecida pela Formadores de Mercado Educação Ltda ou pela respectiva plataforma, a qual deverá ser posteriormente alterada pelo Usuário. Caso a Formadores de Mercado Educação Ltda detecte alguma conta criada a partir de informações falsas, por Usuários que não possuam capacidade legal ou permissão para se cadastrar na Plataforma, ou que, de qualquer modo, violem este Termo, a respectiva conta será deletada.
            <br/>
            3.5. Senhas de acesso: o Usuário se compromete a manter seu login e senha seguros e fora do alcance de terceiros, e não permitirá que a sua conta na Plataforma seja usada por outras pessoas. Dessa forma, o Usuário se responsabiliza por todas as ações realizadas em sua conta. A Formadores de Mercado Educação Ltda não se responsabiliza por operações efetuadas através do cadastro dos Usuários na Plataforma. Caso perceba o uso ou o acesso não autorizado do seu cadastro, o Usuário deverá comunicar tal fato à Formadores de Mercado Educação Ltda imediatamente para fins de suspensão do cadastro e resolução da questão.
            <br/>
            3.5.1. Manutenção: é de sua inteira responsabilidade manter o ambiente de seu dispositivo (computador, celular, tablet, entre outros) seguro, com o uso de ferramentas disponíveis, como antivírus, firewall, entre outras, de modo a contribuir na prevenção de riscos eletrônicos.
            <br/>
            3.6. O compartilhamento dos dados de acesso e cadastro: não é permitido sob hipótese alguma, de modo que o Assinante poderá ter seu acesso bloqueado se o sistema identificar acesso simultâneo por diferentes IPs.
            <br/>
            3.7. Uso da Plataforma: mediante a conclusão de seu cadastro e adesão a este Termo, o Usuário poderá usar a Plataforma para assistir as Aulas e usufruir de suas funcionalidades, conforme previsto neste Termo.
            <br/>
            3.7.1. É absolutamente proibida qualquer ação ou uso de dispositivo, software, ou outro meio com o propósito de interferir nas atividades e operações da Plataforma. Qualquer intromissão, atividade ou tentativa de violação que seja contrária às leis, incluindo, sem limitação, as que versem sobre direito de propriedade intelectual, e/ou as proibições estipuladas neste Termo, serão passíveis da adoção das medidas legais pertinentes, inclusive de natureza criminal.
            <br/>
            3.8. Exclusão e Suspensão do Cadastro e do Acesso à Plataforma: A Formadores de Mercado Educação Ltda se reserva no direito de suspender e cancelar, a qualquer momento, o acesso de determinado Usuário à Plataforma em caso de suspeita de fraude, obtenção de benefício ou vantagem de forma ilícita, ou pelo não cumprimento de quaisquer condições previstas neste Termo ou na legislação aplicável. Nesses casos, não será devida qualquer indenização ao respectivo Usuário, e a Formadores de Mercado Educação Ltda poderá promover a competente ação de regresso, se necessário, bem como adotar quaisquer outras medidas necessárias para perseguir e resguardar os seus interesses.
            <br/>
            3.8.1. A Formadores de Mercado Educação Ltda poderá excluir da Plataforma os Usuários que não atuem em consonância com este Termo ou a legislação brasileira aplicável, independentemente de prévia notificação, oportunidade em que serão excluídos os cadastros e acessos do Usuário das Plataformas, sem prejuízo da cobrança de todos os valores devidos pelo Usuário em decorrência deste Termo e do uso das respectivas Plataformas e eventuais reparações.
            <br/>
            3.9. É absolutamente proibido aos Assinantes qualquer ação ou uso de dispositivo, software, ou outro meio com o propósito de interferir nas atividades e operações da Plataforma. Qualquer intromissão, atividade ou tentativa de violação que seja contrária às leis, incluindo, sem limitação, as que versem sobre direito de propriedade intelectual, e/ou às proibições estipuladas neste Termo, serão passíveis das ações legais pertinentes pela Formadores de Mercado Educação Ltda.
            <br/><br/>

            4. REGRAS DE PAGAMENTO DA PLATAFORMA
            <br/>
            4.1. Pagamento da Assinatura: O acesso às Aulas e demais funcionalidades da Plataforma ocorrerão mediante pagamento pelo Usuário (“Assinatura”).
            <br/>
            4.1.1. O pagamento da Assinatura é feito de forma antecipada, concedendo ao Usuário o direito de acesso à Plataforma pelo período contratado imediatamente subsequente ao pagamento.
            <br/>
            4.1.2. O Usuário não ficará isento do pagamento da assinatura mesmo que não venha a acessar a Plataforma e/ou que não venha a assistir as Aulas, sendo o pagamento devido 
            pela simples contratação da plataforma e disponibilização do acesso ao Usuário.
            <br/>
            4.2. Meios de Pagamento. Os pagamentos efetuados pelos Usuário deverão ser realizados dentro da própria Plataforma indicada pela Formadores de Mercado Educação Ltda, a exemplo da plataforma Hotmart, ou em página específica e direcionada, por meio de um dos meios de pagamento disponibilizados pela Formadores de Mercado Educação Ltda.
            <br/>
            4.3. Confirmação e Processamento dos Pagamentos: o processamento das informações de pagamento e a confirmação do pagamento serão realizados por sistemas de terceiros (ex. instituição financeira ou administradora do cartão de crédito) (“Empresas de Pagamento”).
            <br/>
            4.3.1. A Formadores de Mercado Educação Ltda não poderá intervir, nem será responsável, de qualquer maneira, pelos atos praticados pelas Empresas de Pagamento e demais plataformas, a exemplo da hotmart, assim como pelos resultados da relação dos Usuários com as Empresas de Pagamento, uma vez que estas administram suas operações de forma independente.
            <br/>
            4.4. Atraso nos Pagamentos: Em caso de atraso no pagamento dos valores referentes à assinatura da Plataforma, o Assinante estará sujeito à cobrança de juros moratórios de 1% (um por cento) ao mês, bem como correção monetária pelo IGPM/FGV, até que ocorra a efetiva regularização dos valores em atraso.
            <br/>
            4.4.1. Ainda em caso de atrasos nos pagamentos, a Formadores de Mercado Educação Ltda poderá, a qualquer tempo, suspender o acesso do Usuário à Plataforma, bem como promover a sua exclusão do acesso ao curso através da respectiva plataforma, independentemente de aviso ou notificação prévia, e sem prejuízo da cobrança dos valores em aberto e adoção das medidas judiciais e extrajudiciais cabíveis.
            <br/>
            4.4.2. A liberação do acesso ao curso através das plataformas só acontecerá mediante o pagamento de todos os valores em atraso.
            <br/>
            4.5. Preço Final e Reajuste. O valor da Assinatura pago pelo Usuário é final e não reembolsável, exceto na hipótese prevista no item 4.6 abaixo. A Formadores de Mercado Educação Ltda se reserva no direito de revisar e alterar o valor da assinatura do curso disponibilizados através das plataformas, mediante prévio aviso aos Usuários, anteriormente à próxima data de cobrança recorrente, se aplicável.
            <br/>
            4.6. Hipóteses de Reembolso da Assinatura: A partir da data da contratação através das plataformas devidas, o Usuário terá o prazo máximo de 7 (sete) dias para solicitar o reembolso da assinatura e ser ressarcido integralmente pelo valor pago.
            <br/>
            4.6.1. Na hipótese de cancelamento antecipado de assinatura, após o período de 7 (sete) dias, não serão devolvidos quaisquer valores ao Usuário, considerando o decurso do prazo legal de arrependimento, considerando totalmente entregue a contraprestação.
            <br/>
            4.7. Código Promocional: Caso a Formadores de Mercado Educação Ltda crie algum código promocional ou cupom de desconto, este deve ser usado de forma legal e adequada, pelo respectivo Assinante, para a finalidade para o qual foi criado, seguindo todas as condições e termos aplicáveis. O código promocional poderá ser cancelado pela Formadores de Mercado Educação Ltda, independentemente de comunicação neste sentido, caso se verifique que foi transferido ou vendido a terceiros, ou foi utilizado com erro, fraude, ilegalidade ou violação às condições do respectivo código ou deste Termo.
            <br/><br/>

            5. DIREITOS DE PROPRIEDADE INTELECTUAL
            <br/>
            5.1. Propriedade Intelectual: Todos os direitos e funcionalidades disponibilizados através das plataformas são de propriedade intelectual exclusiva da Formadores de Mercado Educação Ltda, especialmente no que diz respeito às aulas e seus vídeos, imagens, textos e materiais, assim como no que diz respeito aos demais materiais, textos, imagens, layouts, software, códigos, bases de dados, gráficos, artigos, fotografias e demais conteúdos constantes nas plataformas disponibilizadas. 
            <br/>
            5.2. O Conteúdo disponibilizado é protegido pela lei de direitos autorais e de propriedade intelectual. É proibido usar, copiar, reproduzir, modificar, traduzir, publicar, transmitir, distribuir, executar, fazer o upload, exibir, licenciar, vender ou explorar e fazer engenharia reversa do conteúdo da plataforma, para qualquer finalidade. Qualquer uso não autorizado do conteúdo será considerado violação dos direitos autorais e de propriedade intelectual da Formadores de Mercado Educação Ltda.
            <br/>
            5.3. Não Aquisição de Direitos: O licenciamento de uso e acesso ao ambiente das plataformas, pela Formadores de Mercado Educação Ltda, não concede ao Usuário quaisquer direitos definitivos de propriedade intelectual sobre o conteúdo, bem como quaisquer outros ativos de propriedade intelectual de titularidade da Formadores de Mercado Educação Ltda na data de aceite do presente Termo, ou que venham a ser desenvolvidos pela Formadores de Mercado Educação Ltda no âmbito deste Termo, especialmente quanto ao material disponibilizado através das plataformas, marcas, materiais, nomes comerciais, nomes de domínio e demais sinais distintivos da Formadores de Mercado Educação Ltda, assim como os programas, banco de dados, redes e demais arquivos, sendo vedado ao Usuário efetuar pedido de registro, em território nacional ou no exterior, em seu nome ou em nome de terceiro, referente a qualquer propriedade industrial, software e/ou direito autoral que se relacione ou seja similar à ao conteúdo disponibilizado, ou a quaisquer outros ativos de propriedade intelectual de titularidade da Formadores de Mercado Educação Ltda.
            <br/>
            5.3.1. O Assinante desde já reconhece que eventuais invenções e melhorias que, eventualmente, venham a ser sugeridas por ele, no âmbito da execução do presente Termo, para o ambiente das plataformas, sejam elas descobertas ou projetadas, passíveis de registro como propriedade intelectual ou não, permanecerão de propriedade e de benefício exclusivo da Formadores de Mercado Educação Ltda.
            <br/>
            5.4. É proibido que o Usuário faça o download do Conteúdo das plataformas com o intuito de armazená-lo em banco de dados para oferecer a terceiros, gratuita ou onerosamente, ou constituir serviço que possa concorrer, de qualquer maneira, com as aulas e atividades realizadas no âmbito das plataformas, ou atentar contra os direitos e a proteção dos dados dos demais Usuários.
            <br/>
            5.5. Infringência de Direito de Propriedade Intelectual: qualquer intromissão, tentativa de intromissão, ou atividade que viole ou contrarie as leis de direito de propriedade intelectual e/ou as proibições estipuladas neste Termo, tornarão o Usuário passível das ações legais pertinentes por parte da Formadores de Mercado Educação Ltda, bem como das sanções aqui previstas, sendo ainda responsável pelas indenizações por eventuais sanções sofridas pela Formadores de Mercado Educação Ltda.
            <br/><br/>

            6. ATUAÇÃO E RESPONSABILIDADES DA FORMADORES DE MERCADO EDUCAÇÃO LTDA
            <br/>
            6.1. O Usuário é exclusivamente responsável pelo uso das ferramentas e funcionalidades oferecidas pela Plataforma disponibilizada, pela autenticidade e correção dos dados fornecidos para uso da Plataforma, bem como por sua conduta ao longo do acompanhamento das Aulas e utilização dos materiais relativos utilizados, devendo respeitar a boa-fé, as regras deste Termo e a legislação aplicável. O uso das informações transmitidas através dos conteúdos disponibilizados na Plataforma é de responsabilidade exclusiva do Usuário.
            <br/>
            6.2. É absolutamente proibida qualquer ação ou uso de dispositivo, software, ou outro meio com o propósito de interferir nas atividades e operações das Plataformas. Qualquer intromissão, atividade ou tentativa de violação, por parte do Usuário, que seja contrária às leis, incluindo, sem limitação, as que versem sobre direitos de propriedade intelectual, e/ou às proibições estipuladas neste Termo, serão passíveis das ações legais pertinentes.
            <br/>
            6.3. A Formadores de Mercado Educação Ltda não será, em hipótese alguma, responsabilizada por danos diretos ou indiretos, danos morais ou lucros cessantes que resultem: (i) de atrasos, por parte do Usuário, na apresentação de documentos e informações necessárias ao seu cadastro e utilização das Plataformas; (ii) de omissões  ou extravio de documentos e informações necessários ao cadastro do Usuário para fins de utilização das Plataformas; (iii) fornecimento de informações inexatas, incompletas, falsas ou fraudulentas que ocasionem quaisquer prejuízos; (iv) da utilização dos conteúdos ministrados pela plataforma, tendo em vista que a maior parte deles decorre da experiência prática dos professores, podendo ou não se aplicar à realidade do Usuário.
            <br/>
            6.4. Plataformas de Terceiros: É de conhecimento dos Usuários a existência de links para sites e aplicativos de terceiros, assim como a existência de tecnologias integradas com plataformas de terceiros. Isso não implica, de maneira alguma, que a Formadores de Mercado Educação Ltda endossa, verifica, garante ou possui qualquer ligação com os proprietários desses sites ou aplicativos, não sendo responsável pelo seu conteúdo, precisão, políticas, práticas ou opiniões. A Formadores de Mercado Educação Ltda recomenda que o Usuário leia os termos de uso e políticas de privacidade de cada site de terceiros ou serviço que venha a visitar ou utilizar. A Formadores de Mercado Educação Ltda não será responsável por quaisquer danos decorrentes de questões relacionadas a serviços ou plataformas de terceiros.
            <br/><br/>

            7. SEGURANÇA DA INFORMAÇÃO E PROTEÇÃO DE DADOS PESSOAIS
            <br/>
            7.1. Para ter acesso ao ambiente das plataformas e usufruir de seu conteúdo e funcionalidades, os Usuários deverão fornecer determinados dados e informações, sendo que alguns deles podem se tratar de dados pessoais. Para mais informações sobre a privacidade dos dados pessoais dos Usuários, o Usuário deverá acessar a Política de Privacidade disponibilizada pela Formadores de Mercado Educação Ltda, bem como a Política de Privacidade disponibilizada pelas respectivas Plataformas de terceiros.
            <br/>
            7.2. Ao realizar qualquer atividade de tratamento de dados pessoais, a Formadores de Mercado Educação Ltda se compromete a:
            A. Aplicar as devidas medidas físicas, técnicas e organizacionais visando assegurar a integridade, a disponibilidade e a confidencialidade dos dados colocados sob sua guarda e responsabilidade, nos termos da legislação aplicável;
            <br/>
            B. Adotar medidas técnicas e administrativas de segurança da informação para evitar o uso indevido e não autorizado de dados pessoais;
            <br/>
            C. Adotar medidas eficazes e capazes de comprovar a observância e o cumprimento das normas de proteção de dados pessoais, bem como garantir a revisão periódica das medidas implementadas;
            <br/>
            D. Garantir a qualidade dos dados pessoais e a transparência sobre o tratamento em relação ao titular, bem como atender às suas requisições quando solicitado.
            <br/>
            7.3. Durante o tratamento de dados pessoais, a Formadores de Mercado Educação Ltda se responsabiliza pela manutenção de registro escrito das atividades de tratamento e pela adoção de padrões de segurança sustentados nas melhores tecnologias disponíveis no mercado, devendo:
            <br/>
            A. Restringir o acesso aos dados pessoais mediante a definição de pessoas habilitadas e responsáveis pelo tratamento;
            <br/>
            B. Adotar medidas técnicas e organizacionais de segurança que garantam a inviolabilidade, a confidencialidade, a disponibilidade e a integridade dos dados pessoais.
            <br/>
            7.4. Caso a Formadores de Mercado Educação Ltda seja destinatária de qualquer ordem judicial ou comunicação oficial que determine o fornecimento ou divulgação de dados pessoais tratados em razão deste Termo, deverá notificar o respectivo Usuário sobre o ocorrido.
            <br/>
            7.5. Na ocorrência de qualquer incidente de segurança que envolva os dados pessoais do Usuário, tratados em razão da presente relação contratual, a Formadores de Mercado Educação Ltda seguirá um plano estruturado por ele próprio, a fim de cientificar o Assinante e adotar as devidas medidas de mitigação.
            <br/>
            7.6. Ao término da relação contratual com o Assinante, por qualquer motivo, a Formadores de Mercado Educação Ltda, a seu exclusivo critério, ou para cumprimento de obrigação legal ou regulatória, ou em exercício regular de seus direitos, irá armazenar, eliminar, anonimizar, e/ou bloquear o acesso aos dados pessoais fornecidos pelo respectivo Assinante, em caráter definitivo ou não.
            <br/><br/>

            8. CONFIDENCIALIDADE
            <br/>
            8.1. Todas as informações fornecidas pelo Usuário à Formadores de Mercado Educação Ltda, que estejam relacionadas, direta ou indiretamente, ao presente Termo, serão tratadas com o mais absoluto sigilo e a mais rigorosa confidencialidade, de modo a evitar, por qualquer meio ou forma, o seu conhecimento e/ou utilização por parte de terceiros, seja durante a vigência deste Termo, ou por 2 (dois) ano após o seu término (“Informações Confidenciais”).
            <br/>
            8.2. A Formadores de Mercado Educação Ltda poderá divulgar Informações Confidenciais somente para seus próprios colaboradores e/ou prepostos que tenham a efetiva e comprovada necessidade de conhecer tais informações, bem como deverá informá-los da existência de normas, políticas internas e/ou acordos aplicáveis, e que os Usuários estarão sujeitos às obrigações de confidencialidade previstas no presente Termo.
            <br/>
            8.3. As Partes se comprometem também a não manter sob arquivo ou guarda, por qualquer meio de registro, Informações Confidenciais, ou eventuais cópias, que possam ser erroneamente interpretadas ou mal utilizadas por terceiros.
            <br/><br/>

            9. VIOLAÇÃO DOS TERMOS DE USO
            <br/>
            9.1. Sem prejuízo de outras medidas, a Formadores de Mercado Educação Ltda poderá advertir, suspender ou cancelar, temporária ou definitivamente, a conta de um Usuário das Plataformas, a qualquer tempo e independentemente de prévio aviso ou notificação, e inclusive dentro do prazo legal de 7 (sete) dias previsto no artigo 49 da Lei nº 8.078/1990 - Código de Defesa do Consumidor, e iniciar as ações legais cabíveis, nas seguintes hipóteses:
            <br/>
            A. Se o Usuário: (i) violar qualquer dispositivo deste Termo; (ii) atrasar o pagamento dos valores devidos; (iii) descumprir com seus deveres de Usuário junto à Formadores de Mercado Educação Ltda e plataformas de Terceiros; ou, (iv) praticar atos fraudulentos ou dolosos;
            <br/>
            B. Se o Usuário efetuar, propositalmente, a prática de ‘Chargeback’, contratando as respectivas  plataformas e tendo acesso ao conteúdo oferecido, porém cancelando, de má-fé, a referida contratação dentro do prazo legal de cancelamento mencionado acima, apenas para obter o reembolso do valor pago, hipótese em que o referido reembolso não será devido;
            <br/>
            C. Se qualquer informação fornecida pelo Usuário estiver incorreta, desatualizada ou for inverídica; ou,
            <br/>
            D. Se a Formadores de Mercado Educação Ltda entender que qualquer atitude do Usuário tenha causado algum dano a terceiros ou a própria Formadores de Mercado Educação Ltda, inclusive a sua imagem e reputação perante o mercado, ou tenha a potencialidade de assim o fazer.
            <br/>
            9.2. O Usuário indenizará a Formadores de Mercado Educação Ltda por qualquer demanda promovida por outros Usuários ou quaisquer terceiros, decorrentes de suas atividades nas Plataformas disponibilizadas, por descumprimento deste Termo ou pela comprovada violação de qualquer lei ou direitos de terceiro.
            <br/><br/>

            10. DISPOSIÇÕES FINAIS
            <br/>
            10.1. Acordo: Este Termo constitui o acordo integral entre as Partes, relativamente ao acesso e uso das Plataformas envolvidas, e prevalece sobre quaisquer acordos anteriores.
            <br/>
            10.2. Alterações: Este Termo será revisto e atualizado periodicamente pela Formadores de Mercado Educação Ltda, que poderá alterá-lo, excluindo, modificando ou inserindo cláusulas ou condições, a seu exclusivo critério. No entanto, o Usuário será informado sempre que houver alguma alteração nas disposições deste Termo, para que possa ter ciência das novas condições.
            <br/>
            10.2.1. Caso o Assinante não concorde com as alterações no Termo, poderá rejeitá-las, mas, infelizmente, isso significará que o Usuário não poderá mais ter acesso e fazer parte das Plataformas e de suas funcionalidades. Se, de qualquer maneira, o Usuário utilizar a Plataforma mesmo depois de comunicado sobre a alteração deste Termo, isso significará que o Usuário concordou com todas as modificações realizadas.
            <br/>
            10.2.2. Em caso de conflito entre as versões antigas deste Termo e as novas versões modificadas, as novas versões posteriores deverão prevalecer.
            <br/>
            10.3. Tributos: Os tributos de qualquer natureza relativos à celebração do presente Termo são de exclusiva responsabilidade do contribuinte devedor do respectivo tributo, conforme definido na legislação tributária.
            <br/>
            10.4. Relações Trabalhistas: Sob nenhuma hipótese ou em qualquer situação, se presumirá a eventual existência, ou se estabelecerá a presunção de qualquer vínculo empregatício, ou obrigações de caráter trabalhista e previdenciário entre as Partes, por si ou com os prepostos ou empregados da outra Parte, nem qualquer das Partes será fiadora das obrigações e encargos trabalhistas e sociais da outra Parte, a qual assume, neste ato, integral responsabilidade por tais obrigações, inclusive civil e penalmente.
            <br/>
            10.5. Anticorrupção: As Partes cumprirão e farão com que todos os seus empregados, subcontratados, consultores, agentes ou representantes cumpram a Lei 12.846/13, e demais leis de anticorrupção aplicáveis. Nenhuma das Partes pagará, oferecerá, prometerá ou dará, direta ou indiretamente, qualquer valor ou coisa de valor, incluindo quaisquer valores a ela pagos em decorrência dos serviços contratados, a qualquer funcionário ou oficial de um governo, empresa ou sociedade controlada pelo governo ou de propriedade do mesmo, partido político, candidato para cargo político, ou a qualquer outra pessoa estando ciente de, ou acreditando que tal valor ou item de valor será transmitido a alguém, para influenciar qualquer ação ou decisão por tal pessoa ou por qualquer órgão governamental com a finalidade de obter, reter ou conduzir negócios para quaisquer das Partes.
            <br/>
            10.6. Invalidade, Ilegalidade e Inaplicabilidade: Se qualquer disposição deste Termo for considerada inválida, ilegal ou inaplicável, isso não afetará as demais disposições, que permanecerão válidas, legais e aplicáveis.
            <br/>
            10.7. Dúvidas e Comunicações. Na medida do possível, todas as comunicações serão realizadas em meio eletrônico, por meio de e-mails para o endereço de e-mail fornecido pelo Usuário no ato de cadastro na Plataforma. Caso você tenha alguma dúvida, comentário ou sugestão, por favor, entre em contato conosco por meio do e-mail formadoresdemercadoeducacao@gmail.com.
            <br/>
            11.8. Foro. As Partes elegem como meio competente para qualquer ação decorrente deste Contrato, o foro da cidade de Jaraguá do Sul/SC, com exclusão de qualquer outro, por mais privilegiado que seja.
            <br/><br/>

            O ASSINANTE, NESTE ATO, DECLARA E GARANTE À FORMADORES DE MERCADO EDUCAÇÃO LTDA QUE LEU, COMPREENDEU E CONCORDA COM TODOS AS DISPOSIÇÕES DESTE TERMO.
            <br/><br/>
            Termos de Uso do Curso “CRYPTOPRO”
            <br/>
            CNPJ nº. 50.652.770/0001-97
            <br/>
            Endereço: Rua Jorge Czerniewicz, n.° 99, Box 713, Bloco B, Edifício Saint Sebastian, Bairro Czerniewicz, Cidade de Jaraguá do Sul, SC, CEP 89.255-000
            <br/><br/>

            Histórico de versões:
            <br/><br/>

            Versão 1.0
            <br/>
            08/11/2023
            <br/><br/>

            Data de Atualização dos Termos de Uso: 08/11/2023
          </p>
        </Column>
      </div>
    </Columns>

    <hr style={{backgroundColor: "#3A2354"}} />

    {/* FOOTER */}
    <Footer />
  </div>);
};

export default TermsAndConditionsCourse;